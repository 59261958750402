import { Card } from '@/shared/ui/base/card'

export default function BillingDetailsSkeleton() {
    return (
        <>
            <div>
                <div className="h-7 w-32 mb-2 bg-gray-300 rounded"></div>
                <div className="h-4 w-full max-w-2xl mb-4 bg-gray-300 rounded"></div>

                <div className="flex flex-col gap-2 mb-4">
                    {/* Plan Card Skeletons */}
                    {[1, 2].map((i) => (
                        <Card key={i} className="p-4 animate-pulse">
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="h-5 w-48 mb-2 bg-gray-300 rounded"></div>
                                    <div className="h-4 w-32 bg-gray-300 rounded"></div>
                                </div>
                                <div className="h-9 w-24 bg-gray-300 rounded"></div>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>

            {/* One-time purchases skeleton */}
            <div>
                <div className="h-7 w-48 mb-2 bg-gray-300 rounded"></div>
                <div className="h-4 w-full max-w-2xl mb-4 bg-gray-300 rounded"></div>

                <Card className="p-4 animate-pulse">
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="h-5 w-48 mb-2 bg-gray-300 rounded"></div>
                            <div className="h-4 w-32 bg-gray-300 rounded"></div>
                        </div>
                        <div className="h-9 w-24 bg-gray-300 rounded"></div>
                    </div>
                </Card>
            </div>

            {/* Manage Billing Button Skeleton */}
            <div className="flex flex-col gap-2 items-start mt-4">
                <div className="h-9 w-32 bg-gray-300 rounded"></div>
                <div className="h-4 w-full max-w-md bg-gray-300 rounded"></div>
            </div>
        </>
    )
}
