import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { raiseIfNotOk } from '@/lib/http-utils'
import { SearchType } from '@/schemas/entities/recent-search.schema'
import {
    CompanySavedSearch,
    companySavedSearchSchema,
    JobsSavedSearch,
    jobsSavedSearchSchema,
    NewCompanySavedSearch,
    NewJobsSavedSearch,
    SavedSearch,
    savedSearchSchema,
} from '@/schemas/entities/saved-search.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/saved_searches'

export async function getAllSearches(type?: SearchType): Promise<SavedSearch[]> {
    const res = await fetch(RESOURCE_URL + (type ? `?types=${type}` : ''), {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(savedSearchSchema).parse(await res.json())
}

export async function getJobSearchById(id: number): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    const data = await res.json()
    const savedSearch = jobsSavedSearchSchema.parse(data)
    savedSearch.body.blur_company_data = true
    return savedSearch
}

export async function getCompanySearchById(id: number): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    const data = await res.json()
    const savedSearch = companySavedSearchSchema.parse(data)
    savedSearch.body.blur_company_data = true
    return savedSearch
}

export async function updateJobSearch(id: number, search: JobsSavedSearch): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(search),
    })
    await raiseIfNotOk(res)
    return jobsSavedSearchSchema.parse(await res.json())
}

export async function updateCompanySearch(id: number, search: CompanySavedSearch): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(search),
    })
    await raiseIfNotOk(res)
    return companySavedSearchSchema.parse(await res.json())
}

export async function renameSearch(id: number, name: string): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ name: name }),
    })
    await raiseIfNotOk(res)
    return res.status
}

export async function archiveSearch(id: number): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}/archive`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return res.status
}

export async function createJobsSearch(newSearch: NewJobsSavedSearch): Promise<JobsSavedSearch> {
    const res = await fetch(RESOURCE_URL, {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(newSearch),
    })
    await raiseIfNotOk(res)
    return jobsSavedSearchSchema.parse(await res.json())
}

export async function createCompanySearch(newSearch: NewCompanySavedSearch): Promise<CompanySavedSearch> {
    const res = await fetch(RESOURCE_URL, {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(newSearch),
    })
    await raiseIfNotOk(res)
    return companySavedSearchSchema.parse(await res.json())
}
