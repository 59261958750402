import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { ScrollWithFadeOut } from '@/components/ui/scroll-fade-out'
import { Request, SearchType } from '@/schemas/entities/recent-search.schema'
import { CompaniesSearchParams } from '@/shared/schemas/request/companies-search-params.schema'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { getUniqueUIRequests } from '@/services/requests.service'

import { Card } from '@/shared/ui/base/card'
import { Separator } from '@/shared/ui/base/separator'
import { Spinner } from '@/shared/ui/base/spinner'
import { timeAgo } from '@/shared/utils/date-utils'
import { useInfiniteQuery } from '@tanstack/react-query'
import { History } from 'lucide-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useIntersectionObserver } from 'usehooks-ts'

export default function RecentSearchesCard({ search_type = undefined }: { search_type?: SearchType | undefined }) {
    const {
        data: searches,
        isPending,
        isError,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<Request[]>({
        queryKey: ['recent-searches', search_type],
        queryFn: ({ pageParam }) => getUniqueUIRequests(search_type, pageParam as number),
        getNextPageParam: (lastPage, allPages) => (lastPage.length === 0 ? undefined : allPages.length),
        initialPageParam: 0,
    })
    const { isIntersecting: isVisible, ref: loadingButtonRef } = useIntersectionObserver({ threshold: 0.5 })
    useErrorNotification({ isError })

    useEffect(() => {
        if (isVisible && hasNextPage) {
            fetchNextPage()
        }
    }, [isVisible])

    return (
        <div className="flex flex-col items-start space-y-4 w-full relative">
            {isPending && (
                <div className="flex flex-col space-y-2 w-full overflow-y-auto max-h-72">
                    {[...Array(8)].map((_, i) => (
                        <Card
                            key={i}
                            className="w-full flex flex-row p-2 space-x-2 items-center justify-between h-10 bg-gray-200 animate-pulse"
                        />
                    ))}
                </div>
            )}
            {!isPending && searches && searches.pages[0].length == 0 && (
                <div className="flex flex-col w-full items-center space-y-4 p-4">
                    <p className="text-sm text-gray-500">You haven't done any searches yet</p>
                </div>
            )}
            {!isPending && searches && searches?.pages[0].length != 0 && (
                <ScrollWithFadeOut className="flex flex-col w-full max-h-72 relative overflow-x-hidden overflow-y-scroll">
                    {searches.pages.map((group, i) => (
                        <div key={i}>
                            {group.map((recent_search, index) => (
                                <div key={recent_search.id}>
                                    <Row key={recent_search.id} recent_search={recent_search} />
                                    {index !== group.length - 1 && <Separator className="mx-1" />}
                                </div>
                            ))}
                        </div>
                    ))}
                    {hasNextPage && (
                        <div ref={loadingButtonRef} className="flex justify-center">
                            <Spinner size="sm" className="m-1" />
                        </div>
                    )}
                </ScrollWithFadeOut>
            )}
        </div>
    )
}

function Row({ recent_search }: { recent_search: Request }) {
    return (
        <>
            {recent_search.type && (
                <Link to={get_search_url(recent_search.type, recent_search.body)}>
                    <div className="flex flex-row p-2 space-x-2 items-center w-full hover:bg-gray-50">
                        <div className="w-8 h-8 rounded-sm bg-slate-200 flex items-center justify-center shrink-0">
                            <History strokeWidth={1.5} />
                        </div>
                        <div className="flex flex-col space-y-1 overflow-hidden">
                            <p className="text-sm hover max-h-5 truncate  text-start"> {recent_search.name}</p>
                            <p className="text-xs text-gray-400">
                                {' '}
                                {recent_search.type == 'jobs' ? 'Job Search' : 'Company Search'} ·{' '}
                                {timeAgo(recent_search.start_datetime)}
                            </p>
                        </div>
                    </div>
                </Link>
            )}
        </>
    )
}

export function get_search_url(search_type: SearchType, search_params: JobsSearchParams | CompaniesSearchParams) {
    return (
        `/search/${search_type}/new?query=` +
        btoa(encodeURIComponent(JSON.stringify({ ...{ query: search_params }, ...{ auto_search: true } })))
    )
}
