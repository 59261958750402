import { EmptyState } from '@/components/ui/empty-state'
import { PageLayout } from '@/components/ui/layout/page-layout'
import { Button } from '@/shared/ui/base/button'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { FileIcon } from 'lucide-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { InvoiceCard } from './invoice-card'
import { InvoiceSkeletonCard } from './invoice-skeleton-card'
import { Invoice } from '@/schemas/entities/invoice'
import { getInvoices } from '@/services/billing.service'

function InvoicePage() {
    const { data: invoices = [], isLoading } = useQuery<Invoice[]>({
        queryKey: ['invoices'],
        queryFn: getInvoices,
        staleTime: HOUR_IN_MS,
    })

    useEffect(() => {
        document.title = 'Invoices · Settings · TheirStack'
    }, [])

    return (
        <PageLayout
            title="Invoices"
            description="View and download your billing history and analyze your service usage details."
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Settings', href: '/settings' },
                { label: 'Invoices' },
            ]}
            titleAction={
                <>
                    <Link to="/usage">
                        <Button variant="outline">Usage</Button>
                    </Link>
                    <Link to="/settings/billing">
                        <Button variant="outline">Billing</Button>
                    </Link>
                </>
            }
        >
            <div className="space-y-6">
                {isLoading ? (
                    <div className="space-y-3">
                        {Array.from({ length: 5 }).map((_, index) => (
                            <InvoiceSkeletonCard key={index} />
                        ))}
                    </div>
                ) : invoices.length === 0 ? (
                    <EmptyState
                        className="pt-12"
                        title="No invoices yet"
                        description="You don't have any invoices yet. Your invoices will appear here once you make a purchase."
                        icon={FileIcon}
                    />
                ) : (
                    <div className="space-y-3">
                        {invoices.map((invoice) => (
                            <InvoiceCard key={invoice.id} invoice={invoice} />
                        ))}
                    </div>
                )}
            </div>
        </PageLayout>
    )
}

export default InvoicePage
