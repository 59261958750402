import { PageLayout } from '@/components/ui/layout/page-layout'
import { Card } from '@/shared/ui/base/card'
import React, { useEffect } from 'react'
import RecentSearchesCard from './components/saved-searches/recent-searches-card'
import SavedSearchCard from './components/saved-searches/saved-search-card'
import StartNewSearch from './components/start-new-search'

const HomePage: React.FC = () => {
    useEffect(() => {
        document.title = 'Home · TheirStack'
    }, [])
    return (
        <PageLayout className=" overflow-auto h-100" breadcrumbs={[{ label: 'Home' }]}>
            <div className="flex flex-col gap-8 items-center">
                <StartNewSearch />
                <div className="w-full">
                    <p className="text-sm text-gray-700 mb-4">Your saved searches</p>
                    <Card className="w-full p-4">
                        <SavedSearchCard />
                    </Card>
                </div>
                <div className="w-full">
                    <p className="text-sm text-gray-700 mb-4">Your recent searches</p>
                    <Card className="w-full p-4">
                        <RecentSearchesCard />
                    </Card>
                </div>
            </div>
        </PageLayout>
    )
}

export default HomePage
