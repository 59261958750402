import { Dataset } from '@/schemas/entities/dataset.schema'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { timeAgo } from '@/shared/utils/date-utils'
import { capitalizeFirstLetter } from '@/shared/utils/string-utils'
import { Download } from 'lucide-react'

interface DatasetCardProps {
    dataset: Dataset
}

function formatBytes(bytes: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let index = 0
    let value = bytes
    while (value >= 1024 && index < units.length - 1) {
        value /= 1024
        index++
    }
    return `${value.toFixed(2)} ${units[index]}`
}

function DatasetCard({ dataset }: DatasetCardProps) {
    return (
        <Card className="p-2">
            <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center gap-4">
                <div className="flex flex-col space-y-2">
                    <p className="text-md font-medium">
                        {capitalizeFirstLetter(dataset.type)}{' '}
                        <span className="text-sm text-muted-foreground">{dataset.version}</span>
                    </p>
                    <p className="text-xs text-gray-500">{dataset.description}</p>
                    <p className="text-sm text-muted-foreground">
                        {timeAgo(dataset.last_updated)} · {dataset.frequency} · {dataset.format} ·{' '}
                        {dataset.size ? formatBytes(dataset.size) : 'Free'}
                    </p>
                </div>
                <div className="flex flex-row flex-wrap gap-2">
                    <a href={dataset.dictionary_url} target="_blank" rel="noopener noreferrer text-sm">
                        <Button variant="outline" size="sm">
                            <Download className="w-4 h-4 mr-2" />
                            Dictionary
                        </Button>
                    </a>
                    <a href={dataset.sample_dataset_url} target="_blank" rel="noopener noreferrer text-sm">
                        <Button variant="outline" size="sm">
                            <Download className="w-4 h-4 mr-2" />
                            Sample
                        </Button>
                    </a>

                    {dataset.dataset_url ? (
                        <a href={dataset.dataset_url} target="_blank" rel="noopener noreferrer text-sm">
                            <Button variant="outline" size="sm">
                                <Download className="w-4 h-4 mr-2" />
                                Dataset
                            </Button>
                        </a>
                    ) : (
                        <Button variant="outline" size="sm" disabled>
                            <Download className="w-4 h-4 mr-2" />
                            Dataset
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    )
}

export default DatasetCard
