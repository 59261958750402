import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { Tabs, TabsList, TabsTrigger } from '../../shared/ui/base/tabs'
import { Spinner } from '../../shared/ui/base/spinner'
import { useNavigate } from 'react-router-dom'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { queryNewCompanySearchSchema } from '@/schemas/entities/query-new-company-search.schema'
import { queryNewJobSearchSchema } from '@/schemas/entities/query-new-job-search.schema'
import { CompaniesSearchParams } from '@/shared/schemas/request/companies-search-params.schema'
import { isObjectEmpty } from '@/lib/object-utils'

export default function SwitchSearchObjectTab({
    totalCompanies,
    totalJobs,
    default_tab,
    job_filters,
    company_filters,
    was_searched,
}: {
    totalCompanies?: number
    totalJobs?: number
    default_tab: 'jobs' | 'companies'
    job_filters?: JobsSearchParams
    company_filters?: CompaniesSearchParams
    was_searched: boolean
}) {
    const navigate = useNavigate()

    const navigateToCompanySearch = () => {
        if (!was_searched) {
            navigate('/search/companies/new')
            return
        }
        if (!job_filters) return
        const search = queryNewCompanySearchSchema.parse({
            name: 'Companies',
            auto_search: true,
            auto_save: false,
            query: {
                page: 0,
                limit: 10,
                job_filters: {
                    ...job_filters,
                },
                ...job_filters,
            },
        })
        if (isObjectEmpty(search.query.job_filters)) {
            delete search.query.job_filters
        } else {
            search.query.order_by = [{ field: 'num_jobs_found', desc: true }]
        }
        if (search.query.tech_filters || search.query.technology_slug_or) {
            search.query.order_by = [
                { field: 'confidence', desc: true },
                { field: 'num_jobs_found', desc: true },
            ]
        }
        navigate('/search/companies/new?query=' + btoa(encodeURIComponent(JSON.stringify(search))))
    }

    const navigateToJobSearch = () => {
        if (!was_searched) {
            navigate('/search/jobs/new')
            return
        }
        if (!company_filters) return
        const search = queryNewJobSearchSchema.parse({
            name: 'Jobs',
            auto_search: true,
            auto_save: false,
            query: {
                page: 0,
                limit: 10,
                ...company_filters,
                ...company_filters.job_filters,
            },
        })
        if (!search.query.posted_at_max_age_days) {
            search.query.posted_at_max_age_days = 15
        }
        search.query.order_by = [{ field: 'num_jobs', desc: true }]
        navigate('/search/jobs/new?query=' + btoa(encodeURIComponent(JSON.stringify(search))))
    }

    const onValueChange = (value: string) => {
        if (value === 'companies') {
            navigateToCompanySearch()
        } else {
            navigateToJobSearch()
        }
    }

    return (
        <Tabs defaultValue={default_tab} onValueChange={onValueChange}>
            <TabsList>
                <TabsTrigger value="companies">
                    Companies
                    {was_searched && totalCompanies != undefined && (
                        <>
                            {totalCompanies > -1 ? (
                                <p className="ml-1">({formatNumberWithComma(totalCompanies)})</p>
                            ) : (
                                <Spinner size="xs" className="ml-1" />
                            )}
                        </>
                    )}
                </TabsTrigger>

                <TabsTrigger value="jobs">
                    Jobs
                    {was_searched && totalJobs != undefined && (
                        <>
                            {totalJobs > -1 ? (
                                <p className="ml-1">({formatNumberWithComma(totalJobs)})</p>
                            ) : (
                                <Spinner size="xs" className="ml-1" />
                            )}
                        </>
                    )}
                </TabsTrigger>
            </TabsList>
        </Tabs>
    )
}
