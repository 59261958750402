import * as React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/base/chart'
import { CreditsConsumption } from '@/schemas/entities/credits-consumption.schema'
import { Card, CardContent } from '@/shared/ui/base/card'
import { Spinner } from '@/shared/ui/base/spinner'
import { formatDateToStringMonthAndDay } from '@/shared/utils/date-utils'
import formatNumberToNDigits, { formatNumberWithComma } from '@/shared/utils/number-utils'
import { format } from 'date-fns'

export const description = 'An interactive bar chart'

const chartConfig = {
    api_credits: {
        label: 'API Credits',
        color: 'hsl(var(--chart-2))',
    },
    ui_credits: {
        label: 'Company Credits',
        color: 'hsl(var(--chart-3))',
    },
} satisfies ChartConfig

export function CreditsUsageChart({ data }: { data: CreditsConsumption[] | undefined }) {
    const chart_data = data?.map((item) => ({
        date: format(new Date(item.period_start), 'yyyy-MM-dd'),
        api_credits: item.api_credits_consumed,
        ui_credits: item.ui_credits_consumed,
    }))
    const [activeChart] = React.useState<keyof typeof chartConfig | null>(null)

    const total = React.useMemo(
        () => ({
            api_credits: chart_data?.reduce((acc, curr) => acc + curr.api_credits, 0),
            ui_credits: chart_data?.reduce((acc, curr) => acc + curr.ui_credits, 0),
        }),
        [chart_data]
    )

    return (
        <>
            {data === null && (
                <div className="flex justify-center">
                    <Spinner size="default" className="m-1" />
                </div>
            )}
            {data !== null && (
                <Card>
                    <CardContent className="px-2 sm:p-2">
                        <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
                            <BarChart
                                accessibilityLayer
                                data={chart_data}
                                margin={{
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    minTickGap={32}
                                    tickFormatter={(date) => formatDateToStringMonthAndDay(date)}
                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={(value) => formatNumberToNDigits(value, 2)}
                                    tickMargin={8}
                                />

                                <ChartTooltip
                                    content={
                                        <ChartTooltipContent
                                            className="w-[200px]"
                                            nameKey="dataKey"
                                            labelFormatter={(date) => formatDateToStringMonthAndDay(date, 'numeric')}
                                        />
                                    }
                                />
                                {activeChart === null ? (
                                    ['api_credits', 'ui_credits'].map((key) => {
                                        const chart = key as keyof typeof chartConfig
                                        return (
                                            <Bar
                                                key={chart}
                                                stackId="a"
                                                dataKey={chart}
                                                fill={`var(--color-${chart})`}
                                            />
                                        )
                                    })
                                ) : (
                                    <Bar dataKey={activeChart} stackId="a" fill={`var(--color-${activeChart})`} />
                                )}
                            </BarChart>
                        </ChartContainer>
                        <div className="flex flex-col items-center justify-start m-4 gap-2">
                            {['api_credits', 'ui_credits'].map((key) => {
                                const chart = key as keyof typeof chartConfig
                                return (
                                    <div
                                        key={chart}
                                        className="flex flex-row items-center justify-between bg-gray-100 rounded-md w-full p-2 "
                                    >
                                        <div className="flex flex-row items-center justify-between">
                                            <span
                                                className="inline-block w-3 h-3 rounded-[2px] mr-2"
                                                style={{ backgroundColor: chartConfig[chart].color }}
                                            ></span>
                                            <p className="text-sm text-muted-foreground"> {chartConfig[chart].label}</p>
                                        </div>
                                        <p className="text-sm text-muted-foreground">
                                            {formatNumberWithComma(total[chart as keyof typeof total])}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </CardContent>
                </Card>
            )}
        </>
    )
}
