import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { useMe } from '@/components/hooks/use-me'
import { cn } from '@/lib/utils'
import { USAGE_PATH } from '@/paths'
import { formatCompactNumber } from '@/shared/utils/number-utils'
import { ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Progress } from '../base/progress'
import { SidebarGroup, SidebarGroupLabel, SidebarMenu } from '../base/sidebar'
import UpgradeButton from '../layout/components/upgrade_button'
import UserSwitcher from '../layout/components/user_switcher'

export default function NavUsage() {
    const { me, isLoading } = useMe()
    const { isImpersonating } = useImpersonating()

    return (
        <SidebarGroup className="group-data-[collapsible=icon]:hidden">
            <Link className="text-sm hover:underline" to={USAGE_PATH}>
                <SidebarGroupLabel className="p-1.5">
                    Usage <ChevronRight className="w-3 h-3" strokeWidth={1} />
                </SidebarGroupLabel>
            </Link>
            {isLoading ? (
                <SidebarMenu className="mb-4">
                    <CreditsUsageSkeleton />
                    <CreditsUsageSkeleton />
                </SidebarMenu>
            ) : (
                <SidebarMenu className="mb-4">
                    <CreditsUsage
                        name="Company credits"
                        used={me?.team.credits_used_current_period || 0}
                        total={me?.team.credits || 0}
                    />
                    <CreditsUsage
                        name="API credits"
                        used={me?.team.api_credits_used_current_period || 0}
                        total={me?.team.api_credits || 0}
                    />
                </SidebarMenu>
            )}
            {me && (me.is_superuser || isImpersonating) ? (
                <div className="flex flex-row gap-2 items-center">
                    <UserSwitcher />
                </div>
            ) : (
                <UpgradeButton />
            )}
        </SidebarGroup>
    )
}

function CreditsUsage({ name, used, total }: { name: string; used: number; total: number }) {
    const progress = (used / total) * 100
    return (
        <div className="flex flex-col p-1.5">
            <div className="flex justify-between">
                <p className="text-xs">{name}</p>
                <p className="text-xs">
                    {formatCompactNumber(used)} of {formatCompactNumber(total)}
                </p>
            </div>
            <Progress
                className={cn('h-1.5 ')}
                indicatorClassName={cn(progress > 80 ? 'bg-red-400' : '')}
                value={progress}
            />
        </div>
    )
}

function CreditsUsageSkeleton() {
    return (
        <div className="flex flex-col p-2">
            <div className="flex justify-between">
                <div className="h-4 w-20 bg-muted animate-pulse rounded" />
                <div className="h-4 w-16 bg-muted animate-pulse rounded" />
            </div>
            <div className="h-1.5 w-full bg-muted animate-pulse rounded mt-2" />
        </div>
    )
}
