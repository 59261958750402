import { z } from 'zod'
import { baseSearchParamsSchema } from '@/shared/schemas/request/base-search-params.schema'

const orderByEnum = z.enum([
    'jobs',
    'jobs_last_7_days',
    'jobs_last_30_days',
    'jobs_last_180_days',
    'last_date_found',
    'first_date_found',
    'relative_occurrence_within_category',
    'theirstack_score',
    'company.employee_count',
    'company.alexa_ranking',
    'company.founded_year',
    'company.annual_revenue_usd',
    'company.total_funding_usd',
    'company.last_funding_round_date',
])

export type OrderBy = z.infer<typeof orderByEnum>

export const jobSearchParamsSchema = baseSearchParamsSchema.extend({
    company_name_or: z.array(z.string()).optional(),
    company_name_case_insensitive_or: z.array(z.string()).optional(),
    company_domain_or: z.array(z.string()).optional(),
    company_domain_not: z.array(z.string()).optional(),
    company_name_not: z.array(z.string()).optional(),
    company_name_partial_match_or: z.array(z.string()).optional(),
    company_name_partial_match_not: z.array(z.string()).optional(),
    company_description_pattern_or: z.array(z.string()).optional(),
    company_description_pattern_not: z.array(z.string()).optional(),
    min_employee_count: z.number().optional().nullable(),
    max_employee_count: z.number().optional().nullable(),
    min_employee_count_or_null: z.number().optional().nullable(),
    max_employee_count_or_null: z.number().optional().nullable(),
    min_funding_usd: z.number().optional().nullable(),
    max_funding_usd: z.number().optional().nullable(),
    funding_stage_or: z.array(z.string()).optional().nullable(),
    industry_id_or: z.array(z.number()).optional().nullable(),
    industry_id_not: z.array(z.number()).optional(),
    company_tags_or: z.array(z.string()).optional(),
    company_type: z.enum(['recruiting_agency', 'direct_employer', 'all']).optional().nullable(),
    company_investors_or: z.array(z.string()).optional().nullable(),
    company_investors_partial_match_or: z.array(z.string()).optional(),
    company_technology_slug_or: z.array(z.string()).optional(),
    company_technology_slug_and: z.array(z.string()).optional(),
    company_technology_slug_not: z.array(z.string()).optional(),
    company_location_pattern_or: z.array(z.string()).optional(),
    company_country_or: z.array(z.string()).optional(),
    company_list_id_or: z.array(z.number()).optional().nullable(),
    company_list_id_not: z.array(z.number()).optional().nullable(),
    job_id_or: z.array(z.number()).optional(),
    job_title_or: z.array(z.string()).optional(),
    job_title_not: z.array(z.string()).optional(),
    job_title_pattern_and: z.array(z.string()).optional(),
    job_title_pattern_or: z.array(z.string()).optional(),
    job_title_pattern_not: z.array(z.string()).optional(),
    job_country_code_or: z.array(z.string()).optional(),
    job_country_code_not: z.array(z.string()).optional(),
    posted_at_max_age_days: z.number().optional().nullable(),
    posted_at_gte: z.string().optional().nullable(),
    posted_at_lte: z.string().optional().nullable(),
    discovered_at_max_age_days: z.number().optional().nullable(),
    discovered_at_min_age_days: z.number().optional().nullable(),
    discovered_at_gte: z.string().optional().nullable(),
    discovered_at_lte: z.string().optional().nullable(),
    company_country_code_or: z.array(z.string()).optional(),
    company_country_code_not: z.array(z.string()).optional(),
    created_at_max_age_days: z.number().optional().nullable(),
    job_description_pattern_or: z.array(z.string()).optional(),
    job_description_pattern_not: z.array(z.string()).optional(),
    remote: z.boolean().optional().nullable(),
    reports_to_exists: z.boolean().optional().nullable(),
    final_url_exists: z.boolean().optional().nullable(),
    revealed_company_data: z.boolean().optional().nullable(),
    only_jobs_with_reports_to: z.boolean().optional().nullable(),
    only_jobs_with_hiring_managers: z.boolean().optional().nullable(),
    min_salary_usd: z.number().optional().nullable(),
    max_salary_usd: z.number().optional().nullable(),
    job_technology_slug_or: z.array(z.string()).optional(),
    job_technology_slug_and: z.array(z.string()).optional(),
    job_technology_slug_not: z.array(z.string()).optional(),
    job_location_pattern_or: z.array(z.string()).optional(),
    job_location_pattern_not: z.array(z.string()).optional(),
    scraper_name_pattern_or: z.array(z.string()).optional(),
    company_linkedin_url_exists: z.boolean().optional().nullable(),
    company_linkedin_url_or: z.array(z.string()).optional(),
})

export type JobsSearchParams = z.infer<typeof jobSearchParamsSchema>
