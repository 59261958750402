import { Switch } from '@/components/ui/base/switch'
import { isRevealCompaniesAutomaticallyEnabled, setIsRevealCompaniesAutomaticallyEnabled } from '../billing.page'

export const CompaniesVisibility = () => {
    return (
        <div>
            <h2 className="text-lg font-medium mb-2">Companies visibility</h2>
            <p className="text-sm text-muted-foreground mb-4">
                When searching, you need to click a button to reveal each company's details. This helps you control your
                credit usage. You can change this setting below to automatically reveal all companies without clicking.
            </p>
            <div className="flex space-x-2 w-80 items-center">
                <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Reveal companies automatically
                </p>
                <Switch
                    defaultChecked={isRevealCompaniesAutomaticallyEnabled()}
                    onCheckedChange={(checked) => setIsRevealCompaniesAutomaticallyEnabled(checked)}
                />
            </div>
        </div>
    )
}
