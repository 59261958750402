import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/ui/base/table'
import { AutoRechargeRule } from '@/schemas/entities/auto-recharge-rule.schema'
import { Button } from '@/shared/ui/base/button'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { ConfirmActionDialog } from '@/components/ui/dialog/confirm-action-dialog'
import { useToast } from '@/components/ui/base/use-toast'
import { useRef } from 'react'
import { removeAutoRechargeRule } from '@/services/billing.service'
import { TrashIcon } from 'lucide-react'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { useQueryClient } from '@tanstack/react-query'

interface AutoRechargeRulesTableProps {
    rules: AutoRechargeRule[]
}

export const AutoRechargeRulesTable = ({ rules }: AutoRechargeRulesTableProps) => {
    return (
        <>
            <Table className="w-auto">
                <TableHeader>
                    <TableRow>
                        <TableHead>When balance is below</TableHead>
                        <TableHead>Recharge</TableHead>
                        <TableHead>Credit type</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rules.map((rule) => (
                        <AutoRechargeRulesTableRow key={rule.id} rule={rule} />
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export const AutoRechargeRulesTableRow = ({ rule }: { rule: AutoRechargeRule }) => {
    const confirmDeleteDialogRef = useRef<OpenDialogHandle>(null)
    const queryClient = useQueryClient()
    const { toast } = useToast()
    return (
        <>
            <TableRow key={rule.id}>
                <TableCell className="pr-10">{formatNumberWithComma(rule.recharge_below)}</TableCell>
                <TableCell className="pr-10">{formatNumberWithComma(rule.recharge_amount)}</TableCell>
                <TableCell className="pr-10">
                    {rule.credit_type === 'api' ? 'API credits' : 'Company credits'}
                </TableCell>
                <TableCell>
                    <Button variant="outline" size="icon_sm" onClick={() => confirmDeleteDialogRef.current?.open()}>
                        <TrashIcon className="w-4 h-4" />
                    </Button>
                </TableCell>
            </TableRow>

            <ConfirmActionDialog
                ref={confirmDeleteDialogRef}
                title={`Are you sure you want to remove this rule?`}
                confirmAction={() => {
                    removeAutoRechargeRule(rule.id)
                        .then(() => {
                            toast({ title: 'Rule has been removed' })
                            confirmDeleteDialogRef.current?.close()
                            queryClient.invalidateQueries({ queryKey: ['auto-recharge-rules'] })
                        })
                        .catch((error) => {
                            toast({
                                title: 'Failed to remove rule',
                                description: error.message,
                                variant: 'destructive',
                            })
                            console.error(error)
                            confirmDeleteDialogRef.current?.close()
                        })
                }}
                cancelAction={() => confirmDeleteDialogRef.current?.close()}
                actionText="Remove rule"
                actionVariant="destructive"
            />
        </>
    )
}
