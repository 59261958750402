import { Webhook } from '@/schemas/entities/webhook.schema'
import { getAllWebhooks } from '@/services/webhook.service'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export function useWebhooks() {
    const { data: webhooks = [], isLoading } = useQuery<Webhook[]>({
        queryKey: ['webhooks'],
        queryFn: getAllWebhooks,
        staleTime: THERE_MINUTE_IN_MS,
    })
    const n_active_webhooks = useMemo(() => {
        return webhooks?.filter((webhook: Webhook) => webhook.is_active).length ?? 0
    }, [webhooks])

    return { webhooks, isLoading, n_active_webhooks }
}
