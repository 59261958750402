import { EVENT_API_CURL_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Braces } from 'lucide-react'
import { WithTooltip } from '../../shared/ui/base/tooltip'
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from './base/sheet'

import API from './sheet/paginated-sheet/pages/api/api'

export default function APICurlButton({
    curl,
    className,
    variant = 'ghost',
    buttonTitle = 'API',
    linkToSearch,
}: {
    curl: string
    className?: string
    variant?: 'ghost' | 'outline'
    buttonTitle?: string
    linkToSearch?: string
}) {
    return (
        <div className={`${className}`}>
            <Sheet>
                <WithTooltip title="Pull this list with our API">
                    <SheetTrigger asChild>
                        <Button
                            variant={variant}
                            size="sm"
                            className="text-sm font-normal"
                            onClick={() => {
                                sendEvent(EVENT_API_CURL_MODAL_VIEW)
                            }}
                        >
                            <Braces className="h-4 w-4 md:mr-2" />
                            <p className="hidden lg:inline">{buttonTitle}</p>
                        </Button>
                    </SheetTrigger>
                </WithTooltip>
                <SheetContent className="w-full md:w-2/3 lg:w-3/5 xl:w-2/5 overflow-y-auto">
                    <SheetTitle>Pull this list with our API</SheetTitle>
                    <API curl={curl} linkToSearch={linkToSearch} />
                </SheetContent>
            </Sheet>
        </div>
    )
}
