import { useFetchDataAndTotalResultsSeparatedForTable } from '@/components/hooks/data-table/use-fetch-data-and-total-results-for-table'
import OutOfCreditsBanner from '@/components/ui/banners/out-of-credits-banner'
import { DataTable } from '@/components/ui/table/data-table'
import DataTableInitialState from '@/components/ui/table/data-table-initial-state'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { Job } from '@/schemas/entities/job.schema'
import { QueryNewJobSearch } from '@/schemas/entities/query-new-job-search.schema'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { exportJobs, getJobs } from '@/services/job.service'
import { useEffect } from 'react'
import { hiddenJobColumns, jobsColumns } from '../../components/columns-job'
import { useColumnsVisibilityBasedOnSearch } from '../../components/use-columns-visibility-based-on-search'
import { DataTableToolbar } from './data-table-toolbar'
import { useJobsTable } from '@/components/ui/sidebar/jobs-table-context'

interface DataTableProps {
    search: QueryNewJobSearch
    onSearchParamsChange?: (params: JobsSearchParams) => void
}

export function DataTableWithJobsToolbar({ search, onSearchParamsChange }: DataTableProps) {
    const { setOnChangeFilters } = useJobsTable()
    const {
        table,
        metadata,
        searchedSP: searchedSearchParams,
        wasFirstSearchTriggered,
        totalResults,
        columnVisibility,
        setColumnVisibility,
        dirtySP: dirtySearchParams,
        isDataFetching,
        isCurrentSearchSearched,
        onChangeFilters,
        onChangeSearch,
        setDirtySP: setDirtySearchParams,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
    } = useFetchDataAndTotalResultsSeparatedForTable<JobsSearchParams, Job>(
        search.query,
        getJobs,
        exportJobs,
        jobsColumns,
        hiddenJobColumns,
        search?.auto_search
    )

    useEffect(() => {
        setOnChangeFilters(onChangeSearch)
        return () => {
            setOnChangeFilters(null)
        }
    }, [onChangeSearch, setOnChangeFilters])

    useColumnsVisibilityBasedOnSearch(searchedSearchParams, columnVisibility, setColumnVisibility)

    useEffect(() => {
        if (dirtySearchParams && onSearchParamsChange) {
            onSearchParamsChange(dirtySearchParams)
        }
    }, [dirtySearchParams, onSearchParamsChange])

    return (
        <>
            {metadata.truncated_results > 0 && <OutOfCreditsBanner hidden_results={metadata.truncated_results} />}
            <DataTableToolbar
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                table={table}
                draft_mode={search.draft_mode || false}
                dirtySearchParams={dirtySearchParams}
                setDirtySearchParams={setDirtySearchParams}
                totalResults={totalResults}
                wasSearched={isCurrentSearchSearched}
                onChangeFilters={onChangeFilters}
                title={search.name || ''}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                metadata={metadata}
            />
            {!wasFirstSearchTriggered && <DataTableInitialState search_type="jobs" />}
            {wasFirstSearchTriggered && (
                <>
                    <DataTable table={table} columns={jobsColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            )}
        </>
    )
}
