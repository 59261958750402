import { z } from 'zod'

export const teamMemberRoleValues = ['owner', 'member'] as const
export const teamMemberRoleSchema = z.enum(teamMemberRoleValues)
export type TeamMemberRole = z.infer<typeof teamMemberRoleSchema>

export const teamMemberSchema = z.object({
    id: z.number(),
    first_name: z.string().nullable().optional(),
    last_name: z.string().nullable().optional(),
    email: z.string(),
    role: teamMemberRoleSchema,
})

export type TeamMember = z.infer<typeof teamMemberSchema>
