import { Button } from '@/shared/ui/base/button'
import { Row, Table } from '@tanstack/react-table'

import { z } from 'zod'

import { useMe } from '@/components/hooks/use-me'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { RadioGroup, RadioGroupItem } from '@/components/ui/base/radio-group'
import { Spinner } from '@/shared/ui/base/spinner'
import CreditsBadge from '@/shared/ui/credits/credits-badge'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotEnoughCredits } from './not-enough-credits'
import { useCalculateOperationCreditsCost } from './use-calculate-operation-credits-cost'

export const ExportFormSchema = z
    .object({
        format: z.enum(['csv', 'xlsx', 'webhook'], {
            required_error: 'You need to select a format.',
        }),
        numberOfRecords: z.enum(['this_page', 'selection', 'all', 'custom'], {
            required_error: 'You need to select the number of companies.',
        }),
        webhook_url: z.string().optional(),
        custom_number_records: z.coerce.number().min(1).optional(),
    })
    .superRefine((data, ctx) => {
        if (
            data.numberOfRecords === 'custom' &&
            (data.custom_number_records === undefined || data.custom_number_records <= 0)
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['custom_number_records'],
                message: 'You need to specify a valid number of records.',
            })
        }
        if (data.format === 'webhook' && !data.webhook_url) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['webhook_url'],
                message: 'Webhook URL is required',
            })
        }
    })

export function ExportForm<R>({
    table,
    recordName,
    all_records_operation_cost,
    onSubmit,
    onCancel,
    isRowBlurred,
    getUniqueCompanyId,
    getIdFromRow,
    showExportToWebhook,
}: {
    table: Table<R>
    recordName: string
    all_records_operation_cost: number
    onSubmit: (data: z.infer<typeof ExportFormSchema>) => void
    onCancel: () => void
    isRowBlurred: (row: R) => boolean
    getUniqueCompanyId: (row: R) => string
    getIdFromRow?: (row: R) => number | string
    showExportToWebhook: boolean
}) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const isThisPageVisible = table.getRowModel().rows.length !== (table.options?.meta?.getTotalResults() || 0)
    const isSelectionVisible =
        table.getSelectedRowModel().rows.length > 0 &&
        table.getSelectedRowModel().rows.length != table.getRowModel().rows.length &&
        table.getSelectedRowModel().rows.every((row) => isRowBlurred(row.original) == false) &&
        getIdFromRow

    const form = useForm<z.infer<typeof ExportFormSchema>>({
        resolver: zodResolver(ExportFormSchema),
        defaultValues: {
            format: 'csv',
            numberOfRecords: isThisPageVisible ? 'this_page' : 'all',
            custom_number_records: table.options?.meta?.getTotalResults(),
            webhook_url: '',
        },
    })

    const countBlurredCompanies = (rows: Row<R>[]) => {
        const blurred_company_ids = rows
            .filter((row) => isRowBlurred(row.original))
            .map((row) => getUniqueCompanyId(row.original))
        const unique_blurred_company_ids = [...new Set(blurred_company_ids)]
        return unique_blurred_company_ids.length
    }

    const thisPageCredits = useMemo(() => {
        return countBlurredCompanies(table.getRowModel().rows)
    }, [table])

    const selectedRowsCredits = useMemo(() => {
        return countBlurredCompanies(table.getSelectedRowModel().rows)
    }, [table])

    const { me } = useMe()
    const {
        isCalculatingCreditsCost: isCalculatingCustomCreditsCost,
        operationCreditsCost: customOperationCreditsCost,
    } = useCalculateOperationCreditsCost({
        table,
        limit: Number(form.getValues('custom_number_records')) || 0,
        enabled: form.watch('numberOfRecords') === 'custom',
    })

    const operationCreditsCost = useMemo(() => {
        const { numberOfRecords } = form.watch()
        if (numberOfRecords === 'this_page') {
            return thisPageCredits
        } else if (numberOfRecords === 'selection') {
            return selectedRowsCredits
        } else if (numberOfRecords === 'all') {
            return all_records_operation_cost
        } else {
            return customOperationCreditsCost || 0
        }
    }, [
        form.watch('numberOfRecords'),
        form.watch('custom_number_records'),
        customOperationCreditsCost,
        thisPageCredits,
        all_records_operation_cost,
    ])

    const hasEnoughCredits = useMemo(() => {
        if (!me) return true
        return me?.team?.credits_left_current_period >= operationCreditsCost
    }, [me?.team?.credits_left_current_period, operationCreditsCost])

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit((data) => {
                    setIsSubmitting(true)
                    onSubmit(data)
                })}
                className="space-y-6"
            >
                <FormField
                    control={form.control}
                    name="numberOfRecords"
                    render={({ field }) => (
                        <FormItem className="space-y-3">
                            <FormLabel>Number of {recordName}</FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-col space-y-1"
                                >
                                    {isThisPageVisible && (
                                        <FormItem className="flex items-center space-x-2 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="this_page" />
                                            </FormControl>
                                            <FormLabel className="font-normal cursor-pointer">
                                                This page{' '}
                                                <span className="text-muted-foreground">
                                                    ({table.getRowModel().rows.length} {recordName})
                                                </span>
                                            </FormLabel>
                                            {thisPageCredits > 0 && <CreditsBadge credits={thisPageCredits} />}
                                        </FormItem>
                                    )}

                                    {isSelectionVisible && (
                                        <FormItem className="flex items-center space-x-2 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="selection" />
                                            </FormControl>
                                            <FormLabel className="font-normal cursor-pointer">
                                                Selected rows{' '}
                                                <span className="text-muted-foreground">
                                                    ({table.getSelectedRowModel().rows.length} {recordName})
                                                </span>
                                            </FormLabel>
                                            {selectedRowsCredits > 0 && <CreditsBadge credits={selectedRowsCredits} />}
                                        </FormItem>
                                    )}

                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="all" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            All{' '}
                                            <span className="text-muted-foreground">
                                                ({formatNumberWithComma(table.options?.meta?.getTotalResults() || 0)}{' '}
                                                {recordName})
                                            </span>
                                        </FormLabel>
                                        {all_records_operation_cost > 0 && (
                                            <CreditsBadge credits={all_records_operation_cost} />
                                        )}
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="custom" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">Custom</FormLabel>
                                    </FormItem>
                                </RadioGroup>
                            </FormControl>
                            {form.watch('numberOfRecords') === 'custom' && (
                                <FormField
                                    control={form.control}
                                    name="custom_number_records"
                                    render={({ field }) => (
                                        <FormItem className="-mt-4 ml-4 ">
                                            <div className="flex items-center gap-2">
                                                <FormControl className="">
                                                    <Input
                                                        className="w-32 h-7 arrow-hide input"
                                                        type="number"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                {isCalculatingCustomCreditsCost && <Spinner className="w-4 h-4" />}
                                                {customOperationCreditsCost != undefined &&
                                                    customOperationCreditsCost > 0 && (
                                                        <CreditsBadge credits={customOperationCreditsCost} />
                                                    )}
                                            </div>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {!hasEnoughCredits && (
                    <NotEnoughCredits
                        credits_left_current_period={me?.team?.credits_left_current_period || 0}
                        operation_credits_cost={operationCreditsCost}
                    />
                )}

                <FormField
                    control={form.control}
                    name="format"
                    render={({ field }) => (
                        <FormItem className="space-y-3">
                            <FormLabel>Format</FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-col space-y-1"
                                >
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="csv" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer">
                                            .CSV (comma separated values)
                                        </FormLabel>
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-2 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value="xlsx" />
                                        </FormControl>
                                        <FormLabel className="font-normal cursor-pointer flex items-center gap-1">
                                            .XLSX
                                            <AppCard app="Excel" image_path="/tools/excel-logo.png" />
                                        </FormLabel>
                                    </FormItem>
                                    {showExportToWebhook && (
                                        <FormItem className="flex items-center space-x-2 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem
                                                    value="webhook"
                                                    onClick={() => {
                                                        onCancel()
                                                        navigate(`${location.pathname}?view=webhook-new`)
                                                    }}
                                                />
                                            </FormControl>
                                            <FormLabel className="font-normal cursor-pointer flex items-center gap-1">
                                                Webhook
                                                <WebhookApps />
                                            </FormLabel>
                                        </FormItem>
                                    )}
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex items-center justify-end gap-2">
                    <Button variant="outline" type="button" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={!hasEnoughCredits || isCalculatingCustomCreditsCost || isSubmitting}
                    >
                        {isSubmitting ? <Spinner className="w-4 h-4" /> : 'Export'}
                    </Button>
                </div>
            </form>
        </Form>
    )
}

function WebhookApps() {
    return (
        <>
            <AppCard app="Clay" image_path="/tools/clay-logo.png" url="https://app.clay.com" />
            <AppCard app="Databar" image_path="/tools/databar-logo.png" url="https://databar.com" />
            <AppCard app="Make" image_path="/tools/make-logo.png" url="https://make.com" />
            <AppCard app="N8N" image_path="/tools/n8n-logo.png" url="https://n8n.com" />
        </>
    )
}

function AppCard({ app, image_path, url }: { app: string; image_path: string; url?: string }) {
    const children = (
        <div className="border  border-input rounded-md px-1 py-0.5 bg-background flex items-center gap-1 hover:bg-muted">
            <img src={image_path} className="h-3.5 w-3.5" />
            {app}
        </div>
    )
    return url ? (
        <a href={url} target="_blank">
            {children}
        </a>
    ) : (
        children
    )
}
