import { Input } from '@/components/ui/base/input'
import APISheet from '@/components/ui/sheet/paginated-sheet/instances/api-sheet'
import ExportButton from '@/components/ui/table/actions/action-export-data/export-button'
import { DataTableFacetedFilter } from '@/components/ui/table/data-table-faceted-filter'
import { TechDetails } from '@/schemas/entities/tech-details.schema'
import { TechSearchParams } from '@/schemas/request/tech-search-params.schema'
import { getTechnographicsCurl } from '@/services/technographics.service'
import { Table } from '@tanstack/react-table'
import { Laptop } from 'lucide-react'
interface DataTableToolbarProps {
    table: Table<TechDetails>
    search_params: TechSearchParams
    filter_by?: string
}
export function DataTableToolbarTechnologies({ table, search_params }: DataTableToolbarProps) {
    return (
        <div className="flex  justify-between">
            <div className="flex items-center flex-wrap gap-2">
                <Input
                    placeholder="Filter by technology..."
                    value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
                    onChange={(event) => table.getColumn('name')?.setFilterValue(event.target.value)}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                <DataTableFacetedFilter
                    icon={Laptop}
                    column={table.getColumn('category_name')}
                    title="Technology Category"
                    options={
                        Array.from(table.getColumn('category_name')?.getFacetedUniqueValues() ?? new Map())
                            .map((item) => item[0])
                            .filter((item) => item != null)
                            .sort()
                            .map((value) => ({ value: value, label: value })) ?? []
                    }
                />
            </div>
            <div className="hidden lg:flex flex-grap gap-1 border rounded-md bg-white">
                <ExportButton<TechDetails>
                    recordName="technologies"
                    isDataFetching={false}
                    table={table}
                    isRowBlurred={() => false}
                    getUniqueCompanyId={() => ''}
                    getIdFromRow={(tech) => tech.technology.slug}
                />
                <APISheet
                    entityName="technologies"
                    curl={getTechnographicsCurl(search_params, { pageIndex: 0, pageSize: 9999 })}
                />
            </div>
        </div>
    )
}
