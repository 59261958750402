import { useMe } from '@/components/hooks/use-me'
import { useAIChat } from '@/components/ui/sidebar/ai-chat-context'
import { cn } from '@/lib/utils'
import { Button } from '@/shared/ui/base/button'
import { WandSparkles } from 'lucide-react'

export function AIChatButton() {
    const { toggleSidebar, isOpen } = useAIChat()
    const { me } = useMe()

    return (
        <>
            {me?.is_superuser && !isOpen && (
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={toggleSidebar}
                    className={cn(!isOpen && 'animate-pulse text-purple-400')}
                >
                    <WandSparkles className="mr-2 h-4 w-4" />
                    Ask AI
                </Button>
            )}
        </>
    )
}
