import { getHTTPHeaders, getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL, raiseIfNotOk, sourceSessionParamsObject } from '@/lib/http-utils'
import { CreditsConsumption, creditsConsumptionSchema } from '@/schemas/entities/credits-consumption.schema'
import { Invoice, invoiceSchema } from '@/schemas/entities/invoice'
import { BillingProduct, billingProductSchema } from '@/shared/schemas/entity/billing-product.schema'
import { getDayEnd } from '@/shared/utils/date-utils'
import { z } from 'zod'
import {
    AutoRechargeRule,
    AutoRechargeRuleCreate,
    autoRechargeRuleSchema,
} from '@/schemas/entities/auto-recharge-rule.schema'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/billing'

export async function getBillingProducts(): Promise<BillingProduct[]> {
    const res = await fetch(RESOURCE_URL + '/products')
    await raiseIfNotOk(res)
    return z.array(billingProductSchema).parse(await res.json())
}

export async function getInvoices(): Promise<Invoice[]> {
    const res = await fetch(RESOURCE_URL + '/invoices', {
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(invoiceSchema).parse(await res.json())
}

export async function getCreditsConsumption(start_datetime: Date, end_datetime: Date): Promise<CreditsConsumption[]> {
    const url = constructURL(import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/teams/credits_consumption', {
        start_datetime: start_datetime.toISOString(),
        end_datetime: getDayEnd(end_datetime).toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    const data = await res.json()
    return z.array(creditsConsumptionSchema).parse(data)
}

export async function cancelSubscription(subscription_id: string): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/cancel_subscription/${subscription_id}`, {
        ...sourceSessionParamsObject(),
    })
    const res = await fetch(url, { method: 'PATCH', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    return res.status === 200
}

export async function getBillingPortalSessionUrl(): Promise<string> {
    const url = constructURL(`${RESOURCE_URL}/billing_portal_session_url`, { ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    return ((await res.json()) as { url: string }).url
}

export async function deleteInvoiceConsumption(invoice_consumption_id: string): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/invoice/${invoice_consumption_id}/consumption`, {
        ...sourceSessionParamsObject(),
    })
    const res = await fetch(url, { method: 'DELETE', headers: getHTTPHeaders() })
    await raiseIfNotOk(res)
    return res.status === 200
}

export async function getAutoRechargeRules(): Promise<AutoRechargeRule[]> {
    const url = constructURL(`${RESOURCE_URL}/auto-recharge-rules`, {
        ...sourceSessionParamsObject(),
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(autoRechargeRuleSchema).parse(await res.json())
}

export async function createAutoRechargeRule(rule: AutoRechargeRuleCreate): Promise<AutoRechargeRule> {
    const url = constructURL(`${RESOURCE_URL}/auto-recharge-rules`, {
        ...sourceSessionParamsObject(),
    })
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            ...getHTTPHeadersImpersonating(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(rule),
    })
    await raiseIfNotOk(res)
    return autoRechargeRuleSchema.parse(await res.json())
}

export async function removeAutoRechargeRule(ruleId: number): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/auto-recharge-rules/${ruleId}`, {
        ...sourceSessionParamsObject(),
    })
    const res = await fetch(url, {
        method: 'DELETE',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return res.status === 200
}
