import { useAuthentication } from '@/components/hooks/authentication/use-authentication'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/base/avatar'
import { Button } from '@/shared/ui/base/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/shared/ui/base/dropdown-menu'
import posthog from 'posthog-js'
import { Link } from 'react-router-dom'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './accordion'

const user_nav_links = [{ name: 'Settings', href: '/settings/billing' }]

export function DesktopUserNav() {
    const { user, logout } = useAuthentication()
    const picture_url = user?.picture ?? ''
    const initials = (user?.given_name?.charAt(0) ?? '') + (user?.family_name?.charAt(0) ?? '')

    const handleLogOut = () => {
        posthog.reset()
        logout()
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                    <Avatar className="h-6 w-6 border ">
                        <AvatarImage src={picture_url} />
                        <AvatarFallback>{initials ?? 'AA'}</AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                            {user?.given_name} {user?.family_name}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground">{user?.email}</p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    {user_nav_links.map((link) => (
                        <Link to={link.href} key={link.href}>
                            <DropdownMenuItem>{link.name}</DropdownMenuItem>
                        </Link>
                    ))}
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onSelect={() => handleLogOut()}>Log out</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export function MobileUserNav({ handleOnClick = () => {} }: { handleOnClick?: () => void }) {
    const { logout } = useAuthentication()

    const handleLogOut = () => {
        posthog.reset()
        logout()
    }
    return (
        <Accordion type="multiple">
            <AccordionItem value="item-1">
                <AccordionTrigger>Settings</AccordionTrigger>
                <AccordionContent>
                    <ul>
                        {user_nav_links.map((link) => (
                            <li key={link.href}>
                                <Link
                                    to={link.href}
                                    className="flex items-center select-none space-x-4 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                                    onClick={handleOnClick}
                                >
                                    <p className="text-sm font-medium leading-none">{link.name}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul>
                        <button
                            className="flex items-center select-none space-x-4 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                            onClick={handleLogOut}
                        >
                            <p className="text-sm font-medium leading-none">Log out</p>
                        </button>
                    </ul>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    )
}
