import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/ui/base/table'
import { Skeleton } from '@/components/ui/base/skeleton'

export const MembersTableSkeleton = () => {
    // Create an array of 5 items to represent loading rows
    const loadingRows = Array(5).fill(null)

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead className="w-[300px]">Name</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Role</TableHead>
                    <TableHead></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {loadingRows.map((_, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Skeleton className="h-5 w-[180px]" />
                        </TableCell>
                        <TableCell>
                            <Skeleton className="h-5 w-[200px]" />
                        </TableCell>
                        <TableCell>
                            <Skeleton className="h-8 w-[140px]" />
                        </TableCell>
                        <TableCell className="flex justify-end">
                            <Skeleton className="h-8 w-[80px]" />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
