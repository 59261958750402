import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { Download } from 'lucide-react'

function DatasetSkeletonCard() {
    return (
        <Card className="p-2 animate-pulse">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                        <div className="h-5 bg-gray-300 rounded w-32"></div>
                        <div className="h-4 bg-gray-300 rounded w-16"></div>
                    </div>
                    <div className="h-3 bg-gray-300 rounded w-64"></div>
                    <div className="h-4 bg-gray-300 rounded w-48"></div>
                </div>
                <div className="flex flex-row space-x-2">
                    <Button variant="outline" size="sm" disabled>
                        <Download className="w-4 h-4 mr-2" />
                        Dictionary
                    </Button>
                    <Button variant="outline" size="sm" disabled>
                        <Download className="w-4 h-4 mr-2" />
                        Sample
                    </Button>
                    <Button variant="outline" size="sm" disabled>
                        <Download className="w-4 h-4 mr-2" />
                        Dataset
                    </Button>
                </div>
            </div>
        </Card>
    )
}

export default DatasetSkeletonCard
