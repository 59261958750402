import { useSavedSearch } from '@/components/hooks/data-table/use-saved-search'
import { JobsSavedSearch } from '@/schemas/entities/saved-search.schema'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PageLayout } from '@/components/ui/layout/page-layout'
import { ShareSearchButton } from '@/components/ui/share-button'
import NotFoundPage from '@/pages/not-found/not-found.page'
import { getJobSearchById } from '@/services/saved-search.service'
import { DataTableWithJobsToolbar } from './components/data-table'

const JobSearchDetailsPage: React.FC = () => {
    const { searchId } = useParams<{ searchId: string }>()
    const {
        search,
        setSearch,
        isFetching: isSearchFetching,
    } = useSavedSearch<JobsSavedSearch>(searchId, getJobSearchById)

    useEffect(() => {
        if (search) document.title = search.name + ' · Search · TheirStack'
    }, [search])

    return (
        <PageLayout
            fullWidth
            overflowHidden
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Search jobs', href: '/search/jobs' },
                { label: search?.name ?? '' },
            ]}
            breadcrumbAction={search?.body && <ShareSearchButton searchType="jobs" searchParams={search.body} />}
        >
            <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden mb-2">
                {search && (
                    <>
                        <DataTableWithJobsToolbar search={search} setSearch={setSearch} />
                    </>
                )}
                {!search && !isSearchFetching && <NotFoundPage />}
            </div>
        </PageLayout>
    )
}

export default JobSearchDetailsPage
