import { PageLayout } from '@/components/ui/layout/page-layout'
import { AddWebhookForm } from '@/components/ui/webhooks/add-webhook-form'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function NewWebhookPage() {
    useEffect(() => {
        document.title = 'New Webhook · Settings · TheirStack'
    }, [])
    const navigate = useNavigate()

    return (
        <PageLayout breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Webhooks' }, { label: 'New' }]}>
            <div className="space-y-6">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="text-lg font-medium">New Webhook</h3>
                    </div>
                </div>
                <AddWebhookForm onSuccess={(id) => navigate(`/webhooks/${id}`)} />
            </div>
        </PageLayout>
    )
}
