import { PageLayout } from '@/components/ui/layout/page-layout'
import { useTeamMembers } from '@/queries/use-team-members.query'
import { useEffect } from 'react'
import InviteMemberButton from './components/invite-member-button'
import { useMe } from '@/components/hooks/use-me'
import { MembersTableSkeleton } from './components/members-table-skeleton'
import { MembersTable } from './components/members-table'

function TeamPage() {
    const { team_members, invalidate, isLoading: isTeamMembersLoading } = useTeamMembers()
    const { me, isLoading: isMeLoading } = useMe()

    useEffect(() => {
        document.title = 'Team · Settings · TheirStack'
    }, [])

    const isLoading = isTeamMembersLoading || isMeLoading
    const isOwner = me && team_members?.find((m) => m.id === me?.id)?.role === 'owner'

    return (
        <PageLayout
            title="Team"
            description="Manage your team members"
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Settings', href: '/settings' }, { label: 'Team' }]}
        >
            <div className="space-y-6">
                <div className="flex justify-between">
                    <div>
                        {!isLoading && team_members && team_members.length <= 1 && (
                            <p className="text-sm text-muted-foreground align-middle">
                                You are the only member of your team.
                            </p>
                        )}
                    </div>

                    <InviteMemberButton onSuccess={invalidate} disabled={!isOwner} />
                </div>

                {isLoading ? (
                    <MembersTableSkeleton />
                ) : (
                    team_members &&
                    team_members.length > 1 && (
                        <MembersTable members={team_members} onRoleChange={invalidate} isOwner={!!isOwner} />
                    )
                )}
            </div>
        </PageLayout>
    )
}

export default TeamPage
