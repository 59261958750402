import { z } from 'zod'

export const datasetSchema = z.object({
    type: z.enum(['jobs', 'companies', 'technologies', 'technographics']).describe('Dataset type'),
    description: z.string().describe('Description of the dataset'),
    version: z.string().describe('Version of the dataset'),
    is_accessible: z.boolean().describe('Is this dataset accessible for the user based on their paid plan?'),
    format: z.enum(['csv', 'json', 'parquet']).describe('Format of the dataset'),
    frequency: z.enum(['daily', 'weekly', 'monthly']).describe('Update frequency of the dataset'),
    dataset_url: z.string().url('Must be a valid URL').nullable().describe('URL of the dataset'),
    dictionary_url: z.string().url('Must be a valid URL').describe('URL of the dictionary'),
    sample_dataset_url: z.string().url('Must be a valid URL').describe('URL of the sample dataset'),
    size: z.number().describe('Size of the dataset in bytes'),
    last_updated: z.string().describe('Last updated date of the dataset'),
    is_deprecated: z.boolean().describe('Is this dataset deprecated?'),
})

export type Dataset = z.infer<typeof datasetSchema>
