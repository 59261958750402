import { Send } from 'lucide-react'
import * as React from 'react'

import { Textarea } from '@/components/ui/base/textarea'
import { Button } from '@/shared/ui/base/button'

interface MessageInputProps {
    onSendMessage: (message: string) => void
    placeholder?: string
}

export function MessageInput({ onSendMessage, placeholder = 'Ask about your search...' }: MessageInputProps) {
    const [input, setInput] = React.useState('')

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (input.trim()) {
            onSendMessage(input)
            setInput('')
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex gap-2 w-full">
            <Textarea
                value={input}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInput(e.target.value)}
                placeholder={placeholder}
                className="min-h-10 resize-none"
                onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        handleSubmit(e)
                    }
                }}
            />
            <Button className="no-wrap" type="submit" size="icon" disabled={!input.trim()}>
                <Send className="h-4 w-4" />
                <span className="sr-only">Send</span>
            </Button>
        </form>
    )
}
