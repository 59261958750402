import { getHTTPHeaders, getHTTPHeadersWithTokenPlaceholder } from '@/lib/authentication/authentication-http-headers'
import { constructURL, fetchToCurl, HTTP_STATUS_CODES, raiseIfNotOk, sourceSessionParamsObject } from '@/lib/http-utils'
import { isRevealCompaniesAutomaticallyEnabled } from '@/pages/settings/billing/billing.page'
import { Pagination } from '@/schemas/entities/pagination.schema'
import { jobSearchParamsSchema, JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { JobCountResponse, jobsCountResponseSchema } from '@/schemas/responses/jobs-count-response.schema'
import { JobResponse, jobsResponseSchema } from '@/schemas/responses/jobs-response.schema'
import { PaginationState } from '@tanstack/react-table'

const RESOURCE_URL = '/v1/jobs/'

export async function getJobs(
    params: JobsSearchParams,
    pagination: Pagination,
    include_total_results: boolean
): Promise<JobResponse> {
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search',
        sourceSessionParamsObject()
    )
    const options = {
        method: 'POST',
        headers: { ...getHTTPHeaders(), 'X-Use-Clickhouse': 'true' },
        body: JSON.stringify(_getJobSearchParams(params, pagination, include_total_results)),
    }
    const res = await fetch(url, options)
    await raiseIfNotOk(res, [HTTP_STATUS_CODES.PAYMENT_REQUIRED])
    return jobsResponseSchema.parse(await res.json())
}

export async function exportJobs(
    params: JobsSearchParams,
    pagination: Pagination,
    format: 'csv' | 'xlsx',
    ids: (number | string)[] | undefined
): Promise<string> {
    params.blur_company_data = false
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + `export?file_format=${format}`,
        sourceSessionParamsObject()
    )
    const body = _getJobSearchParams(params, pagination, false)

    if (ids) {
        body.job_id_or = ids as number[]
    }

    const options = {
        method: 'POST',
        headers: { ...getHTTPHeaders(), 'X-Use-Clickhouse': 'true' },
        body: JSON.stringify(body),
    }
    const res = await fetch(url, options)
    await raiseIfNotOk(res, [HTTP_STATUS_CODES.PAYMENT_REQUIRED])
    const response = (await res.json()) as { url: string }
    return response.url
}

export async function getJobsCount(params: JobsSearchParams, pagination: PaginationState): Promise<JobCountResponse> {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search/counts_within_limit',
        sourceSessionParamsObject()
    )
    const res = await fetch(url, {
        method: 'POST',
        headers: { ...getHTTPHeaders(), 'X-Use-Clickhouse': 'true' },
        body: JSON.stringify(newParams),
    })

    await raiseIfNotOk(res)

    return jobsCountResponseSchema.parse(await res.json())
}

export function getJobsCURL(params: JobsSearchParams, pagination: Pagination, transform: boolean = true): string {
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search'
    const body = _getJobSearchParams(params, pagination, false)
    body.blur_company_data = false
    const options = {
        method: 'POST',
        headers: getHTTPHeadersWithTokenPlaceholder(),
        body: JSON.stringify(transform ? body : params),
    }
    return fetchToCurl(url, options)
}

export function _getJobSearchParams(
    params: JobsSearchParams,
    pagination: Pagination,
    include_total_results: boolean
): JobsSearchParams {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    newParams.offset = pagination.offset
    newParams.include_total_results = include_total_results
    if (newParams.blur_company_data === undefined) {
        newParams.blur_company_data = isRevealCompaniesAutomaticallyEnabled() ? false : true
    }
    return newParams
}

export async function generateFiltersFromText(text: string): Promise<JobsSearchParams> {
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'filters/ai-translate',
        sourceSessionParamsObject()
    )
    const options = {
        method: 'POST',
        headers: { ...getHTTPHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            text: text,
        }),
    }
    const res = await fetch(url, options)
    await raiseIfNotOk(res)
    const data = await res.json()
    const jobSearchParams = jobSearchParamsSchema.parse(data)
    jobSearchParams.blur_company_data = true
    return jobSearchParams
}
