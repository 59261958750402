import { getBillingProducts } from '@/services/billing.service'
import { getLocation } from '@/services/geolocation.service'
import { EVENT_CONTACT_SALES_CLICK, EVENT_SUBSCRIBE_CLICK, sendEvent } from '@/services/tracking.service'
import { getCheckoutSessionUrl } from '@/services/user.service'
import { BillingProduct } from '@/shared/schemas/entity/billing-product.schema'
import { Geolocation } from '@/shared/schemas/response/geolocation.schema'
import { Button } from '@/shared/ui/base/button'
import PricingBox from '@/shared/ui/pricing/pricing-box'
import { useQuery } from '@tanstack/react-query'
import { Spinner } from '../../../../shared/ui/base/spinner'
import { useMe } from '@/components/hooks/use-me'
import { useEffect, useState } from 'react'
import { getActiveCurrencyFromPlans } from '@/schemas/entities/plan'

export default function PricingGrid({
    mode = 'both',
    defaultTab = 'app',
}: {
    mode?: 'both' | 'one_time' | 'recurring'
    defaultTab?: 'app' | 'api' | 'dataset'
}) {
    const { me } = useMe()

    const { data: products = [] } = useQuery<BillingProduct[]>({
        queryKey: ['billing-products'],
        queryFn: getBillingProducts,
        staleTime: Infinity,
    })

    const { data: location } = useQuery<Geolocation>({
        queryKey: ['geolocation'],
        queryFn: getLocation,
        staleTime: Infinity,
    })

    const [currency, setCurrency] = useState<'eur' | 'usd'>()

    useEffect(() => {
        if (me && location) {
            const activeCurrency = getActiveCurrencyFromPlans(me)
            if (activeCurrency) {
                setCurrency(activeCurrency)
            } else {
                setCurrency(location.currency)
            }
        }
    }, [location, me])

    const onSubscribe = (price_id: string | undefined) => {
        if (!price_id) return
        if (price_id == 'contact_sales') {
            sendEvent(EVENT_CONTACT_SALES_CLICK)
            window.location.replace('https://theirstack.com/en/contact')
        } else {
            sendEvent(EVENT_SUBSCRIBE_CLICK)
            getCheckoutSessionUrl(price_id).then((url) => {
                window.location.replace(url)
            })
        }
    }

    const showPricingBox = products.length > 0 && currency

    return (
        <>
            {showPricingBox && (
                <PricingBox
                    defaultTab={defaultTab}
                    mode={mode}
                    products={products}
                    currency={currency}
                    onBillingPriceSelected={onSubscribe}
                    showFreePlan={false}
                />
            )}
            {!showPricingBox && (
                <div className="flex items-center justify-center mt-10">
                    <Spinner />
                </div>
            )}

            <div className="flex items-center justify-center mt-6">
                <a
                    href="https://theirstack.com/en/pricing#faqs"
                    target="_blank"
                    className="cursor-pointer underline text-sm"
                >
                    <Button variant="outline">Frequently asked questions</Button>
                </a>
            </div>
        </>
    )
}
