import { Table } from '@tanstack/react-table'

import { z } from 'zod'

import { EVENT_EXPORT_CLICK, sendEvent } from '@/services/tracking.service'
import { Spinner } from '@/shared/ui/base/spinner'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { ExportForm, ExportFormSchema } from './export-form'
import { useCalculateOperationCreditsCost } from './use-calculate-operation-credits-cost'
import { Link } from 'react-router-dom'
import { useToast } from '@/components/ui/base/use-toast'

export type DataToExport = { records: string[][]; columns_with_links: number[] }

export function ExportDialogContent<R>({
    table,
    recordName,
    closeDialog,
    isRowBlurred,
    getUniqueCompanyId,
    getIdFromRow,
    showExportToWebhook,
}: {
    table: Table<R>
    recordName: string
    closeDialog: () => void
    isRowBlurred: (row: R) => boolean
    getUniqueCompanyId: (row: R) => string
    getIdFromRow?: (row: R) => number | string
    showExportToWebhook: boolean
}) {
    const [isFetchingDataToExport, setIsFetchingDataToExport] = useState(false)
    const [fileUrl, setFileUrl] = useState<string | null>(null)
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { operationCreditsCost, isCalculatingCreditsCost } = useCalculateOperationCreditsCost({
        table,
        limit: table.options?.meta?.getTotalResults() || 0,
    })
    const page = useMemo(() => {
        if (isCalculatingCreditsCost) return 'calculating_credits_cost'
        if (isFetchingDataToExport) return 'fetching_data_to_export'
        if (fileUrl) return 'export_ready'
        return 'export_form'
    }, [isCalculatingCreditsCost, isFetchingDataToExport, fileUrl])

    const onSubmit = async (data: z.infer<typeof ExportFormSchema>) => {
        if (data.format === 'webhook') {
            return // This should not happen, but for type safety
        }

        let numberOfRecordsToExport = 0
        let idsToExport = undefined

        if (data.numberOfRecords === 'this_page') {
            numberOfRecordsToExport = table.getRowModel().rows.length
        } else if (data.numberOfRecords === 'selection' && getIdFromRow) {
            numberOfRecordsToExport = table.getSelectedRowModel().rows.length
            idsToExport = table.getSelectedRowModel().rows.map((row) => getIdFromRow(row.original))
        } else if (data.numberOfRecords === 'all') {
            numberOfRecordsToExport = table.options?.meta?.getTotalResults() || 0
        } else if (data.numberOfRecords === 'custom') {
            numberOfRecordsToExport = data.custom_number_records || 0
        } else {
            throw new Error('Invalid number of records to export')
        }

        if (!table.options?.meta?.exportToFile) throw new Error('Export to file not implemented')

        setIsFetchingDataToExport(true)
        table.options?.meta
            ?.exportToFile(data.format, 0, numberOfRecordsToExport, idsToExport)
            .then((url) => {
                setIsFetchingDataToExport(false)
                sendEvent(EVENT_EXPORT_CLICK, { format: data.format, type: recordName })
                setFileUrl(url)
                window.open(url, '_blank')
                queryClient.invalidateQueries({ queryKey: ['me'] })
            })
            .catch((error) => {
                setIsFetchingDataToExport(false)
                console.error(error)
                toast({
                    title: 'Error exporting data',
                    description: error.message,
                    variant: 'destructive',
                })
            })
    }

    return (
        <>
            {page === 'calculating_credits_cost' && (
                <div className="flex items-center justify-center">
                    <Spinner />
                </div>
            )}
            {page === 'export_form' && operationCreditsCost != undefined && (
                <ExportForm
                    table={table}
                    recordName={recordName}
                    all_records_operation_cost={operationCreditsCost}
                    onSubmit={onSubmit}
                    onCancel={closeDialog}
                    isRowBlurred={isRowBlurred}
                    getUniqueCompanyId={getUniqueCompanyId}
                    getIdFromRow={getIdFromRow}
                    showExportToWebhook={showExportToWebhook}
                />
            )}
            {page === 'fetching_data_to_export' && (
                <>
                    <p className="text-sm text-muted-foreground">
                        Downloading records, this may take a few minutes. Please wait.
                    </p>
                    <div className="flex items-center justify-center mt-4">
                        <Spinner />
                    </div>
                </>
            )}
            {page === 'export_ready' && (
                <>
                    <div className="flex items-center">
                        <p className="text-sm text-muted-foreground">
                            Your export is ready. If you don't see your file,{' '}
                            <Link
                                to={fileUrl || ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-700 font-medium"
                            >
                                you can try downloading it again.
                            </Link>
                        </p>
                    </div>
                </>
            )}
        </>
    )
}
