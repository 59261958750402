'use client'

import { cn } from '@/lib/utils'
import {
    ChevronLeft,
    CreditCard,
    Database,
    FileText,
    HomeIcon,
    KeyRound,
    LifeBuoy,
    List,
    Plug,
    Search,
    Send,
    Settings,
    User,
    Users,
    BarChart3Icon,
    Webhook,
} from 'lucide-react'
import * as React from 'react'

import { useWebhooks } from '@/components/hooks/use-webhooks'
import { Sidebar, SidebarContent, SidebarFooter } from '@/components/ui/base/sidebar'
import { NavLinks } from '@/components/ui/sidebar/nav-links'
import { NavMain } from '@/components/ui/sidebar/nav-main'
import { DATASETS_PATH, HOME_PATH, WEBHOOKS_PATH } from '@/paths'
import { Link, useLocation } from 'react-router-dom'
import { CompanySearchBar } from '../layout/components/company-search-bar'
import HelpButton from '../layout/components/help_button'
import { DesktopUserNav } from '../layout/components/user_nav'
import NavUsage from './nav-usage'

const data = {
    navMain: [
        {
            title: 'Home',
            url: '/home',
            icon: HomeIcon,
        },
        {
            title: 'Search',
            url: '/search/companies/new',
            icon: Search,
            isActive: true,
            items: [
                {
                    title: 'Jobs',
                    url: '/search/jobs/new',
                },
                {
                    title: 'Companies',
                    url: '/search/companies/new',
                },
            ],
        },

        {
            title: 'Company lists',
            url: '/company-list',
            icon: List,
        },
        {
            title: 'Settings',
            url: '/settings',
            icon: Settings,
        },
    ],
    navSecondary: [
        {
            title: 'Support',
            url: '#',
            icon: LifeBuoy,
        },
        {
            title: 'Feedback',
            url: '#',
            icon: Send,
        },
    ],
    developer: [
        {
            name: 'Webhooks',
            url: WEBHOOKS_PATH,
            icon: Webhook,
            count: 0,
        },
        {
            name: 'Datasets',
            url: DATASETS_PATH,
            icon: Database,
        },
        {
            name: 'API Keys',
            url: '/settings/api-key',
            icon: KeyRound,
        },
    ],
    settings: [
        {
            name: 'Profile',
            url: '/settings/profile',
            icon: User,
        },
        {
            name: 'Team',
            url: '/settings/team',
            icon: Users,
        },
        {
            name: 'Billing',
            url: '/settings/billing',
            icon: CreditCard,
        },
        {
            name: 'Usage',
            url: '/usage',
            icon: BarChart3Icon,
        },
        {
            name: 'Invoices',
            url: '/settings/invoices',
            icon: FileText,
        },
        {
            name: 'API Key',
            url: '/settings/api-key',
            icon: KeyRound,
        },
        {
            name: 'Integrations',
            url: '/settings/integrations',
            icon: Plug,
        },
    ],
}

function SidebarArea({ visible, children }: { visible: boolean; children: React.ReactNode }) {
    return (
        <div
            className={cn(
                'left-0 top-0 flex w-full h-full flex-col transition-[opacity,transform] duration-300',
                visible ? 'opacity-100 relative' : 'pointer-events-none absolute opacity-0 translate-x-full'
            )}
            aria-hidden={!visible}
            {...{ inert: !visible ? '' : undefined }}
        >
            {children}
        </div>
    )
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { pathname } = useLocation()
    const currentArea = React.useMemo(() => {
        return pathname.startsWith('/settings') ? 'settings' : 'main'
    }, [pathname])
    const { n_active_webhooks } = useWebhooks()
    const developer_data = React.useMemo(() => {
        return data.developer.map((item) => ({
            ...item,
            count: item.name === 'Webhooks' ? n_active_webhooks : undefined,
        }))
    }, [data.developer, n_active_webhooks])
    return (
        <Sidebar variant="inset" {...props}>
            <div className="flex flex-row justify-between items-center px-2">
                {currentArea === 'main' ? (
                    <Link to={HOME_PATH} className="col-span-2">
                        <span className="sr-only">TheirStack</span>
                        <img className="h-3.5 place-self-end" src="/theirstack-letters.webp" alt=" TheirStack Logo" />
                    </Link>
                ) : (
                    <div className="flex flex-row gap-0 items-center">
                        <Link to={HOME_PATH} className="flex flex-row gap-0 items-center text-sm font-medium">
                            <ChevronLeft className="w-4 h-4" />
                            Settings
                        </Link>
                    </div>
                )}
                <div className="flex flex-row gap-0 items-center">
                    <HelpButton />
                    <DesktopUserNav />
                </div>
            </div>
            <div className="relative w-full h-full overflow-hidden">
                <SidebarArea visible={currentArea === 'main'}>
                    <SidebarContent className="gap-4">
                        <CompanySearchBar />
                        <NavMain items={data.navMain} />
                        <NavLinks links={developer_data} label="Developers" />
                    </SidebarContent>
                </SidebarArea>
                <SidebarArea visible={currentArea === 'settings'}>
                    <SidebarContent className="gap-4">
                        <NavLinks links={data.settings} label="Settings" />
                    </SidebarContent>
                </SidebarArea>
            </div>
            <SidebarFooter>
                <NavUsage />
            </SidebarFooter>
        </Sidebar>
    )
}
