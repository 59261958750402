import { PageLayout } from '@/components/ui/layout/page-layout'
import { WebhookList } from '@/components/ui/webhooks/webhook-list'
import { Button } from '@/shared/ui/base/button'
import { Plus } from 'lucide-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function WebhooksPage() {
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Webhooks · Settings · TheirStack'
    }, [])

    return (
        <PageLayout
            title="Webhooks"
            description="Receive notifications when events occur (new jobs, tech changes) and trigger actions in external systems like CRMs, Slack, or Airtable."
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Webhooks' }]}
            titleAction={
                <Button
                    onClick={() => {
                        navigate('/webhooks/new')
                    }}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    New Webhook
                </Button>
            }
        >
            <div className="space-y-6">
                <WebhookList
                    handleView={(id) => {
                        navigate(`/webhooks/${id}`)
                    }}
                />
            </div>
        </PageLayout>
    )
}
