import { PageLayout } from '@/components/ui/layout/page-layout'
import { Card } from '@/shared/ui/base/card'
import React, { useEffect } from 'react'
import CompanyListCard from '../home/components/company-list/company-list-card'

const CompanylistPage: React.FC = () => {
    useEffect(() => {
        document.title = 'Home · TheirStack'
    }, [])

    return (
        <PageLayout
            title="Company lists"
            description="Use company lists to save companies you want to keep track of or to exclude from searches."
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Company lists' }]}
        >
            <div className="flex flex-col gap-8 items-center">
                <Card className="w-full p-4">
                    <CompanyListCard />
                </Card>
            </div>
        </PageLayout>
    )
}

export default CompanylistPage
