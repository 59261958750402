import { PageLayout } from '@/components/ui/layout/page-layout'
import { WebhookDetails } from '@/components/ui/webhooks/webhook-details/webhook-details'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function WebhookDetailsPage() {
    const { id: webhookId } = useParams<{ id: string }>()

    const navigate = useNavigate()
    useEffect(() => {
        document.title = 'Webhook Details · Settings · TheirStack'
    }, [])

    return (
        <PageLayout
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Webhooks', href: '/webhooks' },
                { label: webhookId ?? '' },
            ]}
        >
            <div className="space-y-6">
                <WebhookDetails
                    webhookId={Number(webhookId)}
                    handleEdit={(id) => navigate(`/webhooks/${id}/edit`)}
                    onArchiveSuccess={() => navigate('/webhooks')}
                />
            </div>
        </PageLayout>
    )
}
