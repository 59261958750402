import { CompanyList } from '@/schemas/entities/company-list.schema'
import { getTotalCredits } from '@/schemas/entities/plan'
import { UserWithTeam } from '@/schemas/entities/user'
import { getAllCompanyLists } from '@/services/company_list.service'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import CreditUsageCard from './credit-usage-card'

export default function CreditsUsage({ me, className }: { me: UserWithTeam; className?: string }) {
    const { data: lists = [] } = useQuery<CompanyList[]>({
        queryKey: ['company-list'],
        queryFn: getAllCompanyLists,
        staleTime: HOUR_IN_MS,
    })

    return (
        <div className={className}>
            <h2 className="text-lg font-medium mb-4">Overview</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <CreditUsageCard
                    title="Company credits usage"
                    description="Company credits are charged when you reveal a company in app.theirstack.com."
                    iconColor="green"
                    creditsUsed={me.team.credits_used_current_period}
                    totalCredits={getTotalCredits(me)}
                    linkText="see companies"
                    linkTo={`/company-list/${lists.find((list) => list.name == 'Companies seen')?.id}`}
                    me={me}
                />
                <CreditUsageCard
                    title="API credits usage"
                    description="API credits are charged when you use the API or Webhooks"
                    iconColor="blue"
                    creditsUsed={me.team.api_credits_used_current_period}
                    totalCredits={me.team.api_credits}
                    me={me}
                    upgradeButtonDefaultTab="api"
                />
            </div>
        </div>
    )
}
