import { PageLayout } from '@/components/ui/layout/page-layout'
import { Dataset } from '@/schemas/entities/dataset.schema'
import { getDatasets } from '@/services/dataset.service'
import { Button } from '@/shared/ui/base/button'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import DatasetCard from './dataset-card'
import DatasetSkeletonCard from './dataset-skeleton-card'

function DatasetsPage() {
    useEffect(() => {
        document.title = 'Datasets · Settings · TheirStack'
    }, [])

    const { data: datasets = [], isLoading } = useQuery<Dataset[]>({
        queryKey: ['datasets'],
        queryFn: getDatasets,
    })

    return (
        <PageLayout
            title="Datasets"
            description="Explore and manage your datasets."
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Datasets' }]}
            titleAction={
                <>
                    <Link to="https://api.theirstack.com/#tag/datasets/GET/v0/datasets" target="_blank">
                        <Button variant="outline">Datasets API</Button>
                    </Link>
                </>
            }
        >
            <div className="space-y-6">
                <div className="grid gap-4 grid-cols-1 mt-6">
                    {isLoading ? (
                        <>
                            <DatasetSkeletonCard />
                            <DatasetSkeletonCard />
                            <DatasetSkeletonCard />
                            <DatasetSkeletonCard />
                        </>
                    ) : (
                        datasets
                            .filter((dataset) => !dataset.is_deprecated)
                            .map((dataset) => <DatasetCard key={dataset.type} dataset={dataset} />)
                    )}
                </div>
            </div>
        </PageLayout>
    )
}

export default DatasetsPage
