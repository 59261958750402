import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/ui/base/table'
import { TeamMember } from '@/schemas/entities/team-members.schema'
import { RoleSelector } from './role-selector'
import RemoveMembersButton from './remove-member-button'

interface MembersTableProps {
    members: TeamMember[]
    onRoleChange: () => void
    isOwner: boolean
}

export const MembersTable = ({ members, onRoleChange, isOwner }: MembersTableProps) => {
    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead className="w-[300px]">Name</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Role</TableHead>
                    <TableHead></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {members.map((member) => (
                    <TableRow key={member.email}>
                        <TableCell>{member.first_name + ' ' + member.last_name}</TableCell>
                        <TableCell>{member.email}</TableCell>
                        <TableCell>
                            <RoleSelector
                                memberId={member.id}
                                currentRole={member.role}
                                onRoleChange={onRoleChange}
                                disabled={!isOwner}
                            />
                        </TableCell>
                        <TableCell className="flex justify-end">
                            <RemoveMembersButton member={member} invalidate={onRoleChange} disabled={!isOwner} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
