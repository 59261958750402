'use client'

import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import * as React from 'react'
import { useCallback, useRef } from 'react'

type JobsTableContext = {
    onChangeFilters: ((newSearchParams: JobsSearchParams) => void) | null
    setOnChangeFilters: (callback: ((newSearchParams: JobsSearchParams) => void) | null) => void
    // Future variables will be added here
}

const JobsTableContext = React.createContext<JobsTableContext | null>(null)

export function useJobsTable() {
    const context = React.useContext(JobsTableContext)
    if (!context) {
        throw new Error('useJobsTable must be used within a JobsTableProvider')
    }
    return context
}

export function JobsTableProvider({ children }: { children: React.ReactNode }) {
    // Use useRef instead of useState to avoid state updates during render
    const onChangeFiltersRef = useRef<((newSearchParams: JobsSearchParams) => void) | null>(null)

    // Create a stable setter function with useCallback
    const setOnChangeFilters = useCallback((callback: ((newSearchParams: JobsSearchParams) => void) | null) => {
        onChangeFiltersRef.current = callback
    }, [])

    const contextValue = React.useMemo<JobsTableContext>(
        () => ({
            // Use a getter function to always access the current value
            get onChangeFilters() {
                return onChangeFiltersRef.current
            },
            setOnChangeFilters,
            // Future variables will be initialized here
        }),
        [setOnChangeFilters]
    )

    return <JobsTableContext.Provider value={contextValue}>{children}</JobsTableContext.Provider>
}
