import { useSavedSearch } from '@/components/hooks/data-table/use-saved-search'
import { PageLayout } from '@/components/ui/layout/page-layout'
import { ShareSearchButton } from '@/components/ui/share-button'
import NotFoundPage from '@/pages/not-found/not-found.page'
import { CompanySavedSearch } from '@/schemas/entities/saved-search.schema'
import { getCompanySearchById } from '@/services/saved-search.service'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataTableWithCompanyToolbar } from './components/data-table'

const SearchCompaniesPage: React.FC = () => {
    const { searchId } = useParams<{ searchId: string }>()
    const { search, setSearch, isFetching } = useSavedSearch<CompanySavedSearch>(searchId, getCompanySearchById)

    useEffect(() => {
        if (search) document.title = search.name + ' · Search · TheirStack'
    }, [search])

    return (
        <PageLayout
            fullWidth
            overflowHidden
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Search companies', href: '/search/companies' },
                { label: search?.name ?? '' },
            ]}
            breadcrumbAction={search?.body && <ShareSearchButton searchType="companies" searchParams={search.body} />}
        >
            <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden mb-2">
                {search && <DataTableWithCompanyToolbar search={search} setSearch={setSearch} />}
                {!search && !isFetching && <NotFoundPage />}
            </div>
        </PageLayout>
    )
}

export default SearchCompaniesPage
