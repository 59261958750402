import { getAutoRechargeRules } from '@/services/billing.service'
import { AutoRechargeAddButton } from './auto-recharge-add'
import { AutoRechargeRulesTable } from './auto-recharge-rules-table'
import { useQuery } from '@tanstack/react-query'
export const AutoRecharge = () => {
    const { data: rules } = useQuery({
        queryKey: ['auto-recharge-rules'],
        queryFn: () => getAutoRechargeRules(),
    })
    return (
        <div>
            <h2 className="text-lg font-medium mb-2">Auto Recharge</h2>
            <p className="text-sm text-muted-foreground mb-4">
                Automatically purchase more credits when your balance is low.
            </p>
            {rules?.length ? (
                <div className="space-y-4">
                    <AutoRechargeRulesTable rules={rules} />
                    {rules.length < 2 && <AutoRechargeAddButton buttonTitle="Add Rule" />}
                </div>
            ) : (
                <AutoRechargeAddButton buttonTitle="Enable auto recharge" />
            )}
        </div>
    )
}
