import { useDeferredValue, useEffect, useState } from 'react'

interface UseDeferredValueOptions {
    /**
     * The delay in milliseconds before updating the deferred value.
     * Set to 0 for immediate update (though still deferred by React).
     */
    delay?: number

    /**
     * Optional transformation function to apply to the value before storing it.
     */
    transform?: (value: any) => any
}

/**
 * Custom hook that extends React's useDeferredValue with additional options.
 *
 * @param value The value to defer
 * @param options Configuration options for customizing the deferred behavior
 * @returns The deferred value
 */
export function useCustomDeferredValue<T>(value: T, options?: UseDeferredValueOptions): T {
    // Use React's built-in useDeferredValue as the foundation
    const deferredValue = useDeferredValue(value)

    // State to hold our custom deferred value with potential delay
    const [customDeferredValue, setCustomDeferredValue] = useState<T>(value)

    useEffect(() => {
        // Apply transformation if provided
        const transformedValue = options?.transform ? options.transform(deferredValue) : deferredValue

        // If no delay is specified or delay is 0, update immediately
        if (!options?.delay) {
            setCustomDeferredValue(transformedValue)
            return
        }

        // Otherwise use the specified delay
        const timeoutId = setTimeout(() => {
            setCustomDeferredValue(transformedValue)
        }, options.delay)

        // Clean up timeout if value changes before the delay completes
        return () => clearTimeout(timeoutId)
    }, [deferredValue, options])

    return customDeferredValue
}
