import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import AdminActions from '@/pages/settings/billing/components/admin-actions'
import { getLatestInvoiceConsumption, isPlanIncomplete, Plan } from '@/schemas/entities/plan'
import { Badge } from '@/shared/ui/base/badge'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { capitalizeFirstLetter } from '@/shared/utils/string-utils'
import { Link } from 'react-router-dom'

export default function OneTimePurchaseCard({ plan }: { plan: Plan }) {
    const { isImpersonating } = useImpersonating()

    return (
        <Card key={plan.name} className="flex flex-row gap-1 p-2 items-center justify-between">
            <div className="flex flex-col gap-2 items-start justify-start">
                <p className="text-md font-medium ">
                    {formatDateTimeStringToReadableDate(plan.current_period_start ?? '')}
                    {plan.status != 'active' && (
                        <Badge variant="warning" className="ml-2 text-xs">
                            {capitalizeFirstLetter(plan.status)}
                        </Badge>
                    )}
                </p>

                <div className="flex flex-col gap-2 items-start justify-start">
                    <p className="text-xs">
                        {plan.credits_used_current_period != null && (
                            <span>{formatNumberWithComma(plan.credits_used_current_period)} used of </span>
                        )}
                        {formatNumberWithComma(plan.credits)} company credits
                    </p>

                    {plan.api_credits > 0 && (
                        <p className="text-xs">
                            {formatNumberWithComma(plan.api_credits_used_current_period)} used of{' '}
                            {formatNumberWithComma(plan.api_credits)} API credits
                        </p>
                    )}
                    {plan.datasets && plan.datasets.length > 0 && (
                        <p className="text-xs">
                            {plan.datasets.length} datasets: {plan.datasets.join(', ')}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-2 items-center justify-start">
                {isPlanIncomplete(plan) && (
                    <Button variant="default" asChild>
                        <Link to={plan.hosted_invoice_url || ''} target="_blank">
                            Complete payment
                        </Link>
                    </Button>
                )}
                {isImpersonating && (
                    <AdminActions
                        invoice_id={getLatestInvoiceConsumption(plan)?.id ?? ''}
                        used_api_credits={getLatestInvoiceConsumption(plan)?.used_api_credits ?? 0}
                        used_ui_credits={getLatestInvoiceConsumption(plan)?.used_ui_credits ?? 0}
                    />
                )}
            </div>
        </Card>
    )
}
