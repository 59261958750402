import { PageLayout } from '@/components/ui/layout/page-layout'
import { NewTechnologyForm } from './components/new-technology-form'

export const NewTechnologyPage = () => {
    return (
        <PageLayout
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Technology', href: '/technology' }, { label: 'New' }]}
        >
            <NewTechnologyForm />
        </PageLayout>
    )
}
