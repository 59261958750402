import { cn } from '@/lib/utils'
import * as React from 'react'
import { Message } from './ai-chat-sidebar'

interface MessageHistoryProps {
    messages: Message[]
    messagesEndRef: React.RefObject<HTMLDivElement>
}

export function MessageHistory({ messages, messagesEndRef }: MessageHistoryProps) {
    return (
        <div className="flex flex-col gap-4 p-4">
            {messages.map((message) => (
                <div
                    key={message.id}
                    className={cn(
                        'flex flex-col rounded-lg p-3 max-w-[85%] overflow-hidden',
                        message.role === 'user' ? 'bg-primary text-primary-foreground self-end' : 'bg-muted self-start'
                    )}
                >
                    <p className="text-sm break-words break-all whitespace-pre-wrap overflow-hidden">
                        {message.content}
                    </p>
                    <span className="text-xs opacity-70 mt-1">
                        {message.timestamp.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </span>
                </div>
            ))}
            <div ref={messagesEndRef} />
        </div>
    )
}
