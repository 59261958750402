import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/base/select'
import { toast } from '@/components/ui/base/use-toast'

import { TeamMemberRole, teamMemberRoleValues } from '@/schemas/entities/team-members.schema'
import { updateTeamMemberRole } from '@/services/team-member.service'
import { useState } from 'react'

interface RoleSelectorProps {
    memberId: number
    currentRole: TeamMemberRole
    onRoleChange: () => void
    disabled: boolean
}

export const RoleSelector = ({ memberId, currentRole, onRoleChange, disabled }: RoleSelectorProps) => {
    const [value, setValue] = useState<TeamMemberRole>(currentRole)

    const handleRoleChange = async (newRole: TeamMemberRole) => {
        await updateTeamMemberRole(memberId, newRole)
            .then(() => {
                toast({
                    title: 'Role updated',
                    description: "Team member's role has been updated successfully",
                })
                setValue(newRole)
                onRoleChange()
            })
            .catch((error) => {
                toast({
                    title: 'Failed to update role',
                    description: error.message,
                    variant: 'destructive',
                })
                console.error('Error updating role:', error)
            })
    }

    return (
        <>
            {disabled && <span className="capitalize">{currentRole}</span>}
            {!disabled && (
                <Select
                    value={value}
                    onValueChange={(value) => handleRoleChange(value as TeamMemberRole)}
                    defaultValue={currentRole}
                >
                    <SelectTrigger className="w-[140px] capitalize">
                        <SelectValue placeholder="Select role" />
                    </SelectTrigger>
                    <SelectContent>
                        {teamMemberRoleValues.map((role) => (
                            <SelectItem key={role} value={role} className="capitalize">
                                {role}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            )}
        </>
    )
}
