import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL, raiseIfNotOk } from '@/lib/http-utils'
import { WebhookCount, webhookCountSchema } from '@/schemas/entities/webhook-count.schema'
import {
    WebhookExecution,
    webhookExecutionSchema,
    WebhookExecutionStatus,
} from '@/schemas/entities/webhook-execution.schema'
import { CreateWebhook, EditWebhook, Webhook, WebhookEventType, webhookSchema } from '@/schemas/entities/webhook.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/webhooks'

export async function getAllWebhooks(): Promise<Webhook[]> {
    const res = await fetch(RESOURCE_URL, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(webhookSchema).parse(await res.json())
}

export async function getWebhook(id: number): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return webhookSchema.parse(await res.json())
}

export async function createWebhook(data: CreateWebhook): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL, {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(data),
    })
    await raiseIfNotOk(res)
    return webhookSchema.parse(await res.json())
}

export async function editWebhook(id: number, data: EditWebhook): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(data),
    })
    await raiseIfNotOk(res)
    return webhookSchema.parse(await res.json())
}

export async function archiveWebhook(id: number): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}/archive`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return webhookSchema.parse(await res.json())
}

export async function updateWebhookStatus(id: number, isActive: boolean): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}/status`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ is_active: isActive }),
    })
    await raiseIfNotOk(res)
    return webhookSchema.parse(await res.json())
}

export async function testWebhook(url: string, event_type: WebhookEventType): Promise<number> {
    const res = await fetch(RESOURCE_URL + '/test', {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ url, event_type }),
    })
    await raiseIfNotOk(res)
    return res.status
}

export async function getWebhookExecutions(
    id: number,
    offset: number = 0,
    limit: number = 10,
    status: WebhookExecutionStatus = 'SUCCESS'
): Promise<WebhookExecution[]> {
    const url = new URL(`${RESOURCE_URL}/${id}/events`)
    url.searchParams.append('offset', offset.toString())
    url.searchParams.append('limit', limit.toString())
    url.searchParams.append('status', status)
    const res = await fetch(url.toString(), {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(webhookExecutionSchema).parse(await res.json())
}

export async function getWebhookExecutionCount(id: number): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}/events/count`, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return res.json()
}

export async function getWebhookExecutionCountPerDay(
    start_datetime: Date,
    end_datetime: Date,
    webhook_id?: number
): Promise<WebhookCount[]> {
    const url = constructURL(RESOURCE_URL + '/events/count', {
        start_datetime: start_datetime.toISOString(),
        end_datetime: end_datetime.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        webhook_id: webhook_id?.toString(),
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    const data = await res.json()
    return z.array(webhookCountSchema).parse(data)
}

export async function retryWebhookExecution(execution_ids: number[]): Promise<boolean> {
    const res = await fetch(RESOURCE_URL + '/events/retry', {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ webhook_execution_ids: execution_ids }),
    })
    await raiseIfNotOk(res)
    return true
}
