'use client'

import { type LucideIcon } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from '@/components/ui/base/sidebar'

export function NavLinks({
    links,
    label,
}: {
    links: {
        name: string
        url: string
        icon: LucideIcon
        count?: number
    }[]
    label: string
}) {
    const location = useLocation()
    const { isMobile, setOpenMobile } = useSidebar()

    const handleLinkClick = () => {
        if (isMobile) {
            setOpenMobile(false)
        }
    }

    return (
        <SidebarGroup className="group-data-[collapsible=icon]:hidden">
            <SidebarGroupLabel>{label}</SidebarGroupLabel>
            <SidebarMenu>
                {links.map((item) => (
                    <SidebarMenuItem key={item.name}>
                        <SidebarMenuButton asChild isActive={location.pathname === item.url}>
                            <Link to={item.url} onClick={handleLinkClick}>
                                <item.icon />
                                <span>{item.name}</span>
                                {item.count !== undefined && item.count > 0 && (
                                    <span className="text-sm text-muted-foreground">({item.count})</span>
                                )}
                            </Link>
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                ))}
            </SidebarMenu>
        </SidebarGroup>
    )
}
