import { getBillingPortalSessionUrl } from '@/services/billing.service'
import { Button } from '@/shared/ui/base/button'

export const ManageBilling = () => {
    return (
        <div>
            <h2 className="text-lg font-medium mb-2">Manage billing</h2>
            <p className="text-sm text-muted-foreground mb-4">
                Click on "Manage Billing" to update your payment method, billing information, or download invoices.
            </p>
            <div className="flex flex-col gap-2 items-start mt-4">
                <Button
                    variant="outline"
                    onClick={async () => {
                        const url = await getBillingPortalSessionUrl()
                        window.open(url)
                    }}
                >
                    Manage Billing
                </Button>
            </div>
        </div>
    )
}
