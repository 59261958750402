import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL, raiseIfNotOk } from '@/lib/http-utils'
import { Pagination } from '@/schemas/entities/pagination.schema'
import { Request, SearchType, requestSchema } from '@/schemas/entities/recent-search.schema'
import { RequestCount, requestCountSchema } from '@/schemas/entities/request-count.schema'
import { RequestSearchParams } from '@/schemas/request/request-search-params.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/requests/'

export async function getUniqueUIRequests(type: SearchType | undefined, page: number): Promise<Request[]> {
    const url = constructURL(RESOURCE_URL + 'unique-ui', {
        types: type ? [type] : ['jobs', 'companies'],
        page: page,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    return z.array(requestSchema).parse(((await res.json()) as { data: Request[] }).data)
}

export async function getRequests(params: RequestSearchParams, pagination: Pagination): Promise<Request[]> {
    const url = constructURL(RESOURCE_URL, {
        start_datetime: params.start_datetime?.toISOString(),
        end_datetime: params.end_datetime?.toISOString(),
        page: pagination.pageIndex,
        limit: pagination.pageSize,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    return z.array(requestSchema).parse(((await res.json()) as { data: Request[] }).data)
}

export async function exportRequests(
    searchParams: RequestSearchParams,
    format: 'csv' | 'xlsx',
    offset: number,
    limit: number
): Promise<string> {
    const url = constructURL(RESOURCE_URL + 'export', {
        file_format: format,
        offset: offset,
        limit: limit,
        start_datetime: searchParams.start_datetime?.toISOString(),
        end_datetime: searchParams.end_datetime?.toISOString(),
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    await raiseIfNotOk(res)
    return res.json()
}

export async function getRequestCount(start_datetime: Date, end_datetime: Date): Promise<RequestCount[]> {
    const url = constructURL(RESOURCE_URL + 'count', {
        start_datetime: start_datetime.toISOString(),
        end_datetime: end_datetime.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    const data = await res.json()
    return z.array(requestCountSchema).parse(data)
}
