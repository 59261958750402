'use client'

import { cn } from '@/lib/utils'
import { X } from 'lucide-react'
import * as React from 'react'

import { useIsMobile } from '@/components/hooks/use-mobile'
import { ScrollArea } from '@/components/ui/base/scroll-area'
import { useAIChat } from '@/components/ui/sidebar/ai-chat-context'
import { useJobsTable } from '@/components/ui/sidebar/jobs-table-context'
import { MessageHistory } from '@/components/ui/sidebar/message-history'
import { MessageInput } from '@/components/ui/sidebar/message-input'
import { generateFiltersFromText } from '@/services/job.service'
import { Button } from '@/shared/ui/base/button'

export interface Message {
    id: string
    content: string
    role: 'user' | 'assistant'
    timestamp: Date
    isHtml?: boolean
}

export function AIChatSidebar() {
    const [messages, setMessages] = React.useState<Message[]>([])
    const { isOpen, setIsOpen, sidebarWidth } = useAIChat()
    const { onChangeFilters } = useJobsTable()
    const isMobile = useIsMobile()
    const messagesEndRef = React.useRef<HTMLDivElement>(null)

    const handleSendMessage = (input: string) => {
        if (!input.trim()) return

        // Add user message
        const userMessage: Message = {
            id: Date.now().toString(),
            content: input,
            role: 'user',
            timestamp: new Date(),
        }

        setMessages((prev) => [...prev, userMessage])

        generateFiltersFromText(input.trim())
            .then((filters) => {
                const aiMessage: Message = {
                    id: (Date.now() + 1).toString(),
                    content: `Searching jobs...`,
                    role: 'assistant',
                    timestamp: new Date(),
                }
                if (onChangeFilters) {
                    onChangeFilters(filters)
                }

                setMessages((prev) => [...prev, aiMessage])
            })
            .catch((error) => {
                console.error('Error generating filters from text:', error)
                setMessages((prev) => [
                    ...prev,
                    {
                        id: (Date.now() + 2).toString(),
                        content: "Sorry, I couldn't generate filters from your text. Please try again.",
                        role: 'assistant',
                        timestamp: new Date(),
                    },
                ])
            })
    }

    // Scroll to bottom when messages change
    React.useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])

    const hasMessages = messages.length > 0

    return (
        <>
            {/* Backdrop for mobile */}
            {isMobile && isOpen && <div className="fixed inset-0 bg-black/50 z-30" onClick={() => setIsOpen(false)} />}

            <div
                className={cn(
                    'fixed top-0 right-0 h-svh z-40 bg-sidebar text-sidebar-foreground ',
                    'transition-all duration-300 ease-in-out',
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                )}
                style={{ width: sidebarWidth }}
            >
                <div className="flex flex-col h-full">
                    <div className="flex flex-row items-center justify-end p-3">
                        <Button variant="ghost" size="icon" onClick={() => setIsOpen(false)} className="h-8 w-8">
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </Button>
                    </div>

                    {hasMessages ? (
                        <>
                            <ScrollArea className="flex-1">
                                <MessageHistory messages={messages} messagesEndRef={messagesEndRef} />
                            </ScrollArea>
                            <div className="p-3 border-t">
                                <MessageInput onSendMessage={handleSendMessage} />
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center flex-1 p-6">
                            <h2 className="text-xl font-semibold mb-6 text-center">What jobs do you need?</h2>
                            <div className="w-full max-w-md">
                                <MessageInput
                                    onSendMessage={handleSendMessage}
                                    placeholder="e.g. software developer jobs in US from software companies"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
