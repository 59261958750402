import React, { useEffect, useState } from 'react'

import { PageLayout } from '@/components/ui/layout/page-layout'
import { ShareSearchButton } from '@/components/ui/share-button'
import { QueryNewCompanySearch, queryNewCompanySearchSchema } from '@/schemas/entities/query-new-company-search.schema'
import { CompaniesSearchParams } from '@/shared/schemas/request/companies-search-params.schema'
import { getLocation } from '@/services/geolocation.service'
import { Geolocation } from '@/shared/schemas/response/geolocation.schema'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import useUrlQueryParams from '../../jobs/new/hooks/use-url-query-params'
import { DataTableWithCompanyToolbar } from './components/data-table'

const default_search: QueryNewCompanySearch = {
    auto_search: false,
    query: {
        page: 1,
        limit: 10,
        order_by: [
            { desc: true, field: 'confidence' },
            { desc: true, field: 'jobs' },
            { desc: true, field: 'num_jobs' },
        ],
    },
}

const NewSearchCompaniesPage: React.FC = () => {
    const [searchParams] = useSearchParams()
    const [search, setSearch] = useState<QueryNewCompanySearch>()
    const [currentSearchParams, setCurrentSearchParams] = useState<CompaniesSearchParams | null>(null)

    const { search: searchFromUrl, isLoading: isLoadingSearch } = useUrlQueryParams<QueryNewCompanySearch>(
        searchParams.get('query'),
        queryNewCompanySearchSchema.parse
    )
    const {
        data: location,
        isLoading,
        isError,
    } = useQuery<Geolocation>({
        queryKey: ['geolocation'],
        queryFn: getLocation,
        staleTime: Infinity,
    })

    useEffect(() => {
        if (isLoadingSearch) return
        setSearch(searchFromUrl ? searchFromUrl : default_search)
        document.title =
            searchFromUrl && searchFromUrl.name
                ? searchFromUrl.name + ' · New company search · TheirStack'
                : 'New company search · TheirStack'
    }, [searchFromUrl, isLoadingSearch])

    useEffect(() => {
        if (searchParams.get('query')) return
        if (!search) return
        if (location && !isLoading && !isError && location.country_code && !search.query.company_country_code_or) {
            const new_search = {
                ...search,
                query: { ...search.query, company_country_code_or: [location.country_code] },
            }
            setSearch(new_search)
        }
    }, [location, search])

    return (
        <PageLayout
            fullWidth
            overflowHidden
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Search companies', href: '/search/companies' },
                { label: 'New' },
            ]}
            breadcrumbAction={
                currentSearchParams && <ShareSearchButton searchType="companies" searchParams={currentSearchParams} />
            }
        >
            <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden mb-2">
                {search && (
                    <DataTableWithCompanyToolbar search={search} onSearchParamsChange={setCurrentSearchParams} />
                )}
            </div>
        </PageLayout>
    )
}

export default NewSearchCompaniesPage
