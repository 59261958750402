import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { cn } from '@/lib/utils'
import { Invoice } from '@/schemas/entities/invoice'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import { formatMoney, formatNumberWithComma } from '@/shared/utils/number-utils'
import { capitalizeFirstLetter } from '@/shared/utils/string-utils'
import { DownloadIcon } from '@radix-ui/react-icons'
import AdminActions from '../billing/components/admin-actions'

interface InvoiceCardProps {
    invoice: Invoice
}

export function InvoiceCard({ invoice }: InvoiceCardProps) {
    const { isImpersonating } = useImpersonating()
    return (
        <Card className="grid grid-cols-2 space-y-2 md:grid-cols-4 lg:grid-cols-8 gap-1 p-2 items-center">
            <div>
                <p className="text-sm font-medium">{invoice.number}</p>
                <p className="text-xs text-muted-foreground">Invoice number</p>
            </div>
            <div>
                <p className="text-sm font-medium">{formatDateTimeStringToReadableDate(invoice.created_at)}</p>
                <p className="text-xs text-muted-foreground">Invoice date</p>
            </div>
            <div>
                <p className="text-sm font-medium">{formatMoney(invoice.total / 100, invoice.currency)}</p>
                <p className="text-xs text-muted-foreground">Invoice amount</p>
            </div>
            <div>
                <p className={cn('text-sm font-medium', invoice.status === 'paid' ? 'text-green-500' : '')}>
                    {capitalizeFirstLetter(invoice.status)}
                </p>
                <p className="text-xs text-muted-foreground">Status</p>
            </div>
            <div>
                <p className="text-sm font-medium">{invoice.has_subscription ? 'Subscription' : 'One-time purchase'}</p>
                <p className="text-xs text-muted-foreground">Type</p>
            </div>
            <div className="col-span-2">
                <div>
                    {invoice.ui_credits > 0 && (
                        <p className="text-xs">
                            {formatNumberWithComma(invoice.used_ui_credits)} used of{' '}
                            {formatNumberWithComma(invoice.ui_credits)} company credits
                        </p>
                    )}
                    {invoice.api_credits > 0 && (
                        <p className="text-xs">
                            {formatNumberWithComma(invoice.used_api_credits)} used of{' '}
                            {formatNumberWithComma(invoice.api_credits)} API credits
                        </p>
                    )}
                </div>
                <p className="text-xs text-muted-foreground">Consumption</p>
            </div>
            <div className="justify-self-end flex flex-row gap-2">
                <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
                    <Button variant="outline" size="icon">
                        <DownloadIcon className="w-4 h-4" />
                    </Button>
                </a>
                {isImpersonating && (
                    <AdminActions
                        invoice_id={invoice.id}
                        used_api_credits={invoice.used_api_credits ?? 0}
                        used_ui_credits={invoice.used_ui_credits ?? 0}
                    />
                )}
            </div>
        </Card>
    )
}
