import { raiseIfNotOk } from '@/lib/http-utils'
import { Geolocation, geolocationSchema } from '@/shared/schemas/response/geolocation.schema'

const RESOURCE_URL = '/v0/misc/geolocate'

export async function getLocation(): Promise<Geolocation> {
    const res = await fetch(`${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}${RESOURCE_URL}`)
    await raiseIfNotOk(res)
    return geolocationSchema.parse(await res.json())
}
