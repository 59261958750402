import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { Textarea } from '@/components/ui/base/textarea'
import { useToast } from '@/components/ui/base/use-toast'
import { isURL } from '@/lib/http-utils'
import { CreateKeyword } from '@/schemas/entities/new-technology'
import { TechnologyCategory } from '@/schemas/entities/technology-category'
import { ExtractedTechnologyDetails } from '@/schemas/entities/technology-extracted-details'
import { getAllTechnologyCategories } from '@/services/technology-category.service'
import { createTechnology, extractTechnologyFromUrl } from '@/services/technology.service'
import { Button } from '@/shared/ui/base/button'
import { Combobox } from '@/shared/ui/base/combobox'
import { zodResolver } from '@hookform/resolvers/zod'
import { ExternalLinkIcon, WandSparklesIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'

const FormSchema = z.object({
    url: z.string().refine((value) => isURL(value), { message: 'Invalid URL' }),
    name: z.string().min(1, { message: 'Name is required' }),
    logo: z.string().url({ message: 'Invalid Logo URL' }).min(1, { message: 'Logo URL is required' }),
    one_liner: z.string().min(1, { message: 'One-liner is required' }),
    description: z.string().min(1, { message: 'Long description is required' }),
    category_slug: z.string().min(1, { message: 'Category is required' }),
})

export const NewTechnologyForm = () => {
    const [categories, setCategories] = useState<TechnologyCategory[]>([])
    const [isLoadingCategories, setIsLoadingCategories] = useState(true)
    const [isLoadingDiscoverTechnology, setIsLoadingDiscoverTechnology] = useState(false)
    const [isLoadingSavingTechnology, setIsLoadingSavingTechnology] = useState(false)
    const { toast } = useToast()

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            url: '',
            name: '',
            logo: '',
            one_liner: '',
            description: '',
            category_slug: '',
        },
    })

    const url = useWatch({ control: form.control, name: 'url' })
    const logo = useWatch({ control: form.control, name: 'logo' })

    const dicoverTechnology = (url: string) => {
        if (url) {
            setIsLoadingDiscoverTechnology(true)
            extractTechnologyFromUrl(url)
                .then((details: ExtractedTechnologyDetails) => {
                    form.setValue('name', details.name || '')
                    form.setValue('logo', details.logo || '')
                    form.setValue('one_liner', details.one_liner || '')
                    form.setValue('description', details.long_description || '')
                    form.setValue('category_slug', details.category_slug || '')
                })
                .finally(() => {
                    setIsLoadingDiscoverTechnology(false)
                })
        }
    }

    useEffect(() => {
        setIsLoadingCategories(true)
        getAllTechnologyCategories()
            .then(setCategories)
            .finally(() => setIsLoadingCategories(false))
    }, [])

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        const technology: CreateKeyword = {
            ...data,
            logo_thumbnail: data.logo,
        }
        setIsLoadingSavingTechnology(true)
        createTechnology(technology)
            .then(() => {
                form.reset()
                toast({
                    title: 'Technology created successfully',
                })
            })
            .catch((err: Error) => {
                toast({
                    title: 'Failed to create technology',
                    description: err.message,
                    variant: 'destructive',
                })
            })
            .finally(() => {
                setIsLoadingSavingTechnology(false)
            })
    }

    return (
        <div className="w-2/3 mx-auto">
            <div className="grid grid-cols-1 gap-4">
                <h1 className="text-2xl font-semibold text-center">Add Technology</h1>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <div className="flex flex-row gap-2 items-end">
                            <FormField
                                control={form.control}
                                name="url"
                                render={({ field }) => (
                                    <FormItem className="flex-grow">
                                        <FormLabel>Website URL</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="url"
                                                id="technology-url"
                                                {...field}
                                                className="border border-gray-300 rounded-md p-2"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex flex-row gap-2">
                                <Button
                                    type="button"
                                    variant="default"
                                    onClick={() => dicoverTechnology(url)}
                                    isLoading={isLoadingDiscoverTechnology}
                                >
                                    <WandSparklesIcon className="w-4 h-4" />
                                </Button>
                                <Button variant="outline" asChild>
                                    <Link
                                        to={`https://${form.getValues('url').replace('https://', '').replace('http://', '')}`}
                                        target="_blank"
                                    >
                                        <ExternalLinkIcon className="w-4 h-4" />
                                    </Link>
                                </Button>
                            </div>
                        </div>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="text"
                                            id="technology-name"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="logo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Logo URL</FormLabel>
                                    <div className="flex flex-row gap-2 justify-between">
                                        <div className="flex-grow">
                                            <FormControl>
                                                <Input
                                                    type="url"
                                                    id="technology-logo"
                                                    {...field}
                                                    className="border border-gray-300 rounded-md p-2"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                        {logo && (
                                            <div className="">
                                                <img
                                                    src={logo}
                                                    alt="Technology Logo"
                                                    className="h-12 w-12 md:h-24 md:w-24 rounded-md p-1 border border-gray-300"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="one_liner"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>One-liner</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="technology-one-liner"
                                            type="text"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            id="technology-long-description"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="category_slug"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Category</FormLabel>
                                    <FormControl>
                                        <Combobox
                                            items={categories.map((cat) => ({
                                                value: cat.slug,
                                                label: cat.name,
                                            }))}
                                            value={field.value?.toString() || ''}
                                            onChange={(value) => field.onChange(value)}
                                            placeholder="Select a category"
                                            searchPlaceholder="Search categories..."
                                            emptyText="No categories found"
                                            isLoading={isLoadingCategories}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex flex-row gap-2 justify-end">
                            <Button type="submit" isLoading={isLoadingSavingTechnology}>
                                Submit for approval
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}
