'use client'

import { useIsMobile } from '@/components/hooks/use-mobile'
import * as React from 'react'

type AIChatContext = {
    isOpen: boolean
    setIsOpen: (open: boolean) => void
    toggleSidebar: () => void
    sidebarWidth: string
}

const AIChatContext = React.createContext<AIChatContext | null>(null)

export function useAIChat() {
    const context = React.useContext(AIChatContext)
    if (!context) {
        throw new Error('useAIChat must be used within an AIChatProvider')
    }
    return context
}

export function AIChatProvider({ children }: { children: React.ReactNode }) {
    const [isOpen, setIsOpen] = React.useState(false)
    const isMobile = useIsMobile()

    // Use a responsive width based on screen size
    const sidebarWidth = isMobile ? '100%' : '350px'

    const toggleSidebar = React.useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const contextValue = React.useMemo<AIChatContext>(
        () => ({
            isOpen,
            setIsOpen,
            toggleSidebar,
            sidebarWidth,
        }),
        [isOpen, toggleSidebar, sidebarWidth]
    )

    return <AIChatContext.Provider value={contextValue}>{children}</AIChatContext.Provider>
}
