import { useAutoSearchTable } from '@/components/hooks/data-table/use-auto-search-table'
import OutOfCreditsBanner from '@/components/ui/banners/out-of-credits-banner'
import { DataTable } from '@/components/ui/table/data-table'
import DataTableInitialState from '@/components/ui/table/data-table-initial-state'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { QueryNewCompanySearch } from '@/schemas/entities/query-new-company-search.schema'
import { CompaniesSearchParams } from '@/shared/schemas/request/companies-search-params.schema'
import { exportCompanies, getCompaniesFromSearchParams } from '@/services/company.service'
import { useEffect } from 'react'
import {
    companyColumns,
    hiddenCompanyColumns,
    updateSearchParamsWithDynamicColumns,
} from '../../components/columns-company'
import { useColumnsVisibilityBasedOnSearch } from '../../components/use-columns-visibility-based-on-search'
import { DataTableToolbar } from './data-table-toolbar'

interface DataTableProps {
    search: QueryNewCompanySearch
    onSearchParamsChange?: (params: CompaniesSearchParams) => void
}

export function DataTableWithCompanyToolbar({ search, onSearchParamsChange }: DataTableProps) {
    const {
        table,
        metadata,
        columnVisibility,
        setColumnVisibility,
        columns,
        setColumns,
        searchedSP,
        isDataFetching,
        totalResults,
        isCurrentSearchSearched,
        rowSelection,
        onChangeFilter,
        onRemoveFilter,
        appliedFilters,
        runSearch,
        addFilters,
    } = useAutoSearchTable<CompaniesSearchParams, FullCompany>(
        search.query,
        companyColumns,
        hiddenCompanyColumns,
        updateSearchParamsWithDynamicColumns,
        getCompaniesFromSearchParams,
        exportCompanies,
        search?.auto_search
    )
    useColumnsVisibilityBasedOnSearch(table, searchedSP, columnVisibility, columns, setColumnVisibility, setColumns)

    // Add this effect to update the parent component when searchedSP changes
    useEffect(() => {
        if (searchedSP && onSearchParamsChange) {
            onSearchParamsChange(searchedSP)
        }
    }, [searchedSP, onSearchParamsChange])

    return (
        <>
            {metadata.truncated_results > 0 && <OutOfCreditsBanner hidden_results={metadata.truncated_results} />}
            <DataTableToolbar
                title={search.name || ''}
                wasSearched={isCurrentSearchSearched}
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                table={table}
                draft_mode={search.draft_mode || false}
                totalResults={totalResults}
                rowSelection={rowSelection}
                searchedSP={searchedSP}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                runSearch={runSearch}
            />
            {!isCurrentSearchSearched && <DataTableInitialState search_type="companies" />}
            {isCurrentSearchSearched && (
                <>
                    <DataTable table={table} columns={companyColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            )}
        </>
    )
}
