import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Textarea } from '@/components/ui/base/textarea'
import { useToast } from '@/components/ui/base/use-toast'
import { Button } from '@/shared/ui/base/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { Label } from '@radix-ui/react-dropdown-menu'
import { useForm } from 'react-hook-form'

import { PageLayout } from '@/components/ui/layout/page-layout'
import { useEffect } from 'react'
import { z } from 'zod'

const formSchema = z.object({
    job_titles: z.string(),
})

const DEFAULT_JOB_TITLES = ['CTO', 'CPO', 'Director', 'Founder', 'VP', 'Chief']

export function getJobCurrentJobTitles() {
    if (localStorage.getItem('job_titles')) {
        return JSON.parse(localStorage.getItem('job_titles') as string)
    } else {
        return DEFAULT_JOB_TITLES
    }
}

function IntegrationsPage() {
    useEffect(() => {
        document.title = 'Integrations · Settings · TheirStack'
    }, [])
    const { toast } = useToast()
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            job_titles: getJobCurrentJobTitles().join('\n'),
        },
    })

    function onSubmit() {
        const new_job_titles = form
            .getValues('job_titles')
            .split('\n')
            .filter((v) => v !== '' && v !== ' ' && v !== '\n' && v !== '\r')
        localStorage.setItem('job_titles', JSON.stringify(new_job_titles))
        toast({ title: 'Job titles updated' })
    }

    return (
        <PageLayout
            title="Integrations"
            description="Manage your integrations."
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Settings', href: '/settings' },
                { label: 'Integrations' },
            ]}
        >
            <div className="space-y-6">
                <div className="space-y-3">
                    <Label>Current job titles for "Find people on"</Label>
                    <p className="text-sm text-muted-foreground">
                        List of current job titles you want to find on Apollo.io, Contact Out, Linkedin, Linkedin Sales
                        Navigator and Linkedin Recruiter.
                    </p>
                    <div className="flex space-x-2">
                        <Form {...form}>
                            <form
                                className="flex flex-col items-baseline  gap-y-4"
                                onSubmit={form.handleSubmit(onSubmit)}
                            >
                                <FormField
                                    control={form.control}
                                    name="job_titles"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Textarea
                                                    className="min-h-[300px] min-w-[300px]"
                                                    placeholder="keyword1&#10;keyword2&#10;keyword3"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button type="submit" size="sm">
                                    Save
                                </Button>
                            </form>
                        </Form>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default IntegrationsPage
