import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { useToast } from '@/components/ui/base/use-toast'
import { PageLayout } from '@/components/ui/layout/page-layout'
import SearchTitleInput from '@/components/ui/search-title-input'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { CompanyFromListSearchParams } from '@/schemas/request/companies-from-list-search-params.schema'
import { exportCompanyFromList, getCompanyFromList, getCompanyList, renameList } from '@/services/company_list.service'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DataTableWithPagination } from './components/data-table'
import { companyColumns } from './components/data-table-columns-company-list-details'
import { useFetchDataForTable } from './components/use-fetch-data-for-table'

const CompanyListDetailsPage: React.FC = () => {
    const { listId } = useParams<{ listId: string }>()
    const { data: list, refetch } = useQuery<CompanyList>({
        queryKey: ['companyList', listId],
        queryFn: () => getCompanyList(+(listId || 0)),
        enabled: !!listId,
        staleTime: HOUR_IN_MS,
    })
    const [savedSearch] = useState<CompanyFromListSearchParams>({
        list_id: listId ? +listId : 0,
        order_by: [{ field: 'revealed_at', desc: true }],
    })
    const { table, isDataFetching, searchedSP, onChangeFilters } = useFetchDataForTable<
        CompanyFromListSearchParams,
        CompanyFromList
    >(savedSearch, getCompanyFromList, exportCompanyFromList, companyColumns, list?.companies_count || 0)
    const { toast } = useToast()
    const { showErrorNotification } = useErrorNotification({ isError: false })

    useEffect(() => {
        if (list) document.title = list.name + ' · List · TheirStack'
    }, [list])

    const handleRenameCompanyList = (name: string) => {
        if (!listId || !list) return
        renameList(+listId, name)
            .then(() => {
                refetch()
                toast({ title: 'Search renamed' })
            })
            .catch(() => {
                showErrorNotification()
            })
    }

    return (
        <PageLayout
            breadcrumbs={[
                { label: 'Home', href: '/' },
                { label: 'Lists', href: '/company-list' },
                { label: list?.name ?? '' },
            ]}
            overflowHidden
        >
            <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden mb-2">
                {!list ? (
                    <CompanyListDetailsPageSkeleton />
                ) : (
                    <>
                        <div className="flex items-center">
                            {list && list.name == 'Companies seen' && (
                                <h2 className="text-2xl font-medium tracking-tight">{list?.name}</h2>
                            )}
                            {list && list.name != 'Companies seen' && (
                                <SearchTitleInput
                                    className="grow overflow-hidden"
                                    name={list?.name}
                                    updateName={handleRenameCompanyList}
                                />
                            )}
                        </div>
                        {searchedSP && (
                            <DataTableWithPagination
                                columns={companyColumns}
                                filter_by="name"
                                isDataFetching={isDataFetching}
                                totalResults={list.companies_count}
                                onChangeFilters={onChangeFilters}
                                searchedSP={searchedSP}
                                table={table}
                            />
                        )}
                    </>
                )}
            </div>
        </PageLayout>
    )
}

const CompanyListDetailsPageSkeleton: React.FC = () => {
    return (
        <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden mb-2">
            <div className="flex items-center justify-between">
                <div>
                    <div className="h-8 w-64 bg-gray-200 rounded animate-pulse"></div>
                </div>
            </div>
            <div className="flex-1 overflow-hidden">
                <div className="h-12 w-full bg-gray-200 rounded animate-pulse mb-4"></div>
                <div className="grid gap-4">
                    {Array(5)
                        .fill(0)
                        .map((_, i) => (
                            <div key={i} className="h-16 w-full bg-gray-200 rounded animate-pulse"></div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default CompanyListDetailsPage
