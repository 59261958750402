import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { useWebhookExecutionCountPerDay } from '@/components/hooks/use-webhook-execution-count'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/base/chart'
import { Webhook } from '@/schemas/entities/webhook.schema'
import { Card, CardContent } from '@/shared/ui/base/card'
import { formatDateToStringMonthAndDay, getTodayEnd } from '@/shared/utils/date-utils'
import formatNumberToNDigits, { formatNumberWithComma } from '@/shared/utils/number-utils'
import { format } from 'date-fns'
import { useMemo } from 'react'

export const description = 'An interactive bar chart'

const chartConfig = {
    SUCCESS: {
        label: 'Successful',
        color: 'hsl(var(--chart-2))',
    },
    FAILED: {
        label: 'Failed',
        color: 'hsl(var(--chart-1))',
    },
    IN_PROGRESS: {
        label: 'In Progress',
        color: 'hsl(var(--chart-4))',
    },
    NOT_TRIGGERED: {
        label: 'Not Triggered',
        color: 'hsl(var(--chart-3))',
    },
} satisfies ChartConfig

export function WebhookExecutionsChart({ start_datetime, webhook }: { start_datetime: Date; webhook?: Webhook }) {
    const { webhookExecutionCount, isLoading } = useWebhookExecutionCountPerDay(
        start_datetime,
        getTodayEnd(),
        webhook?.id
    )

    const chart_data = webhookExecutionCount.reduce((acc: any[], item) => {
        const existingDate = acc.find((d) => d.date === format(new Date(item.period_start), 'yyyy-MM-dd'))
        if (existingDate) {
            existingDate[item.status] = item.number_of_executions
        } else {
            acc.push({
                date: format(new Date(item.period_start), 'yyyy-MM-dd'),
                [item.status]: item.number_of_executions,
            })
        }
        return acc
    }, [])

    const total = useMemo(
        () => ({
            SUCCESS: chart_data?.reduce((acc, curr) => acc + (curr.SUCCESS || 0), 0),
            FAILED: chart_data?.reduce((acc, curr) => acc + (curr.FAILED || 0), 0),
            IN_PROGRESS: chart_data?.reduce((acc, curr) => acc + (curr.IN_PROGRESS || 0), 0),
        }),
        [chart_data]
    )

    return (
        <>
            {isLoading ? (
                <WebhookExecutionsChartSkeleton />
            ) : webhookExecutionCount.length > 0 ? (
                <Card>
                    <CardContent className="p-2 sm:p-4">
                        <ChartContainer config={chartConfig} className="aspect-auto h-[150px] w-full">
                            <BarChart
                                accessibilityLayer
                                data={chart_data}
                                margin={{
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    minTickGap={32}
                                    tickFormatter={(date) => formatDateToStringMonthAndDay(date)}
                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={(value) => formatNumberToNDigits(value, 2)}
                                    tickMargin={2}
                                />

                                <ChartTooltip
                                    content={
                                        <ChartTooltipContent
                                            className="w-[150px]"
                                            labelFormatter={(date) => formatDateToStringMonthAndDay(date, 'numeric')}
                                        />
                                    }
                                />
                                <Bar stackId="a" dataKey="SUCCESS" fill={chartConfig.SUCCESS.color} />
                                <Bar stackId="a" dataKey="FAILED" fill={chartConfig.FAILED.color} />
                                <Bar stackId="a" dataKey="IN_PROGRESS" fill={chartConfig.IN_PROGRESS.color} />
                            </BarChart>
                        </ChartContainer>
                        <div className="flex flex-col items-center justify-start m-4 gap-1">
                            {Object.entries(chartConfig)
                                .filter(([status]) => total[status as keyof typeof total] > 0)
                                .map(([status, config]) => (
                                    <div
                                        key={status}
                                        className="flex flex-row items-center justify-between bg-gray-100 rounded-md w-full p-2"
                                    >
                                        <div className="flex flex-row items-center justify-between">
                                            <span
                                                className="inline-block w-3 h-3 rounded-[2px] mr-2"
                                                style={{ backgroundColor: config.color }}
                                            ></span>
                                            <p className="text-xs text-muted-foreground">{config.label}</p>
                                        </div>
                                        <p className="text-xs text-muted-foreground">
                                            {formatNumberWithComma(total[status as keyof typeof total])}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </CardContent>
                </Card>
            ) : null}
        </>
    )
}

export function WebhookExecutionsChartSkeleton() {
    return <div className="h-[150px] bg-gray-200 rounded animate-pulse" />
}
