import { z } from 'zod'
import { companiesSearchParamSchema } from '@/shared/schemas/request/companies-search-params.schema'

export const queryNewCompanySearchSchema = z.object({
    name: z.string().optional(),
    auto_search: z.boolean(),
    auto_save: z.boolean().nullable().optional(),
    draft_mode: z.boolean().nullable().optional(),
    query: companiesSearchParamSchema,
})

export type QueryNewCompanySearch = z.infer<typeof queryNewCompanySearchSchema>
