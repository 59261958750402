import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { raiseIfNotOk } from '@/lib/http-utils'
import { Dataset, datasetSchema } from '@/schemas/entities/dataset.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/datasets'

export async function getDatasets(): Promise<Dataset[]> {
    const res = await fetch(RESOURCE_URL, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return z.array(datasetSchema).parse(await res.json())
}
