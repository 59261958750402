import { WebhookExecution } from '@/schemas/entities/webhook-execution.schema'
import { Webhook } from '@/schemas/entities/webhook.schema'
import { retryWebhookExecution } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import { timeAgo } from '@/shared/utils/date-utils'
import { Check, ChevronDown, ChevronUp, CircleCheck, CircleDot, CircleX, RotateCcw, X } from 'lucide-react'
import { useState } from 'react'

interface WebhookExecutionCardProps {
    execution: WebhookExecution
    webhook?: Webhook
}

export function WebhookExecutionCard({ execution, webhook }: WebhookExecutionCardProps) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [resendStatus, setResendStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')

    const handleResend = async () => {
        setResendStatus('loading')
        await retryWebhookExecution([execution.id])
            .then(() => {
                setResendStatus('success')
                setTimeout(() => setResendStatus('idle'), 3000)
            })
            .catch(() => {
                setResendStatus('error')
                setTimeout(() => setResendStatus('idle'), 3000)
            })
    }

    return (
        <div className="border p-2 border-gray-200 rounded-md">
            <div className="flex justify-start gap-2 items-center">
                {execution.status === 'SUCCESS' && <CircleCheck className="h-4 w-4 text-green-600" />}
                {execution.status === 'IN_PROGRESS' && <CircleDot className="h-4 w-4 text-yellow-600" />}
                {execution.status === 'FAILED' && <CircleX className="h-4 w-4 text-red-600" />}
                {webhook?.event_type === 'job_new' && (
                    <p className="text-sm">
                        job.new <span className="text-gray-500">(job_id :{execution.job_id})</span>
                    </p>
                )}
                {webhook?.event_type === 'company_new' && (
                    <p className="text-sm w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                        company.new <span className="text-gray-500">{execution.company_name}</span>
                    </p>
                )}
                <p className="text-sm text-gray-500 ml-auto whitespace-nowrap">{timeAgo(execution.triggered_at)}</p>
                <Button variant="ghost" size="icon" className="h-6 w-6" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                </Button>
            </div>

            {isExpanded && (
                <div className="mt-2 pt-2 border-t">
                    <div className="space-y-2">
                        <div className="flex justify-between items-center">
                            <div>
                                <span className="text-sm">Response Status </span>
                                {execution.response_status_code ? (
                                    <span
                                        className={`text-sm ${
                                            execution.response_status_code &&
                                            execution.response_status_code >= 200 &&
                                            execution.response_status_code < 300
                                                ? 'text-green-600'
                                                : 'text-red-600'
                                        }`}
                                    >
                                        {execution.response_status_code}
                                    </span>
                                ) : (
                                    <span className="text-sm text-gray-500">N/A</span>
                                )}
                            </div>

                            <Button
                                variant={
                                    resendStatus === 'idle' || resendStatus === 'loading'
                                        ? 'outline'
                                        : resendStatus === 'success'
                                          ? 'default'
                                          : 'destructive'
                                }
                                size="sm"
                                onClick={handleResend}
                                isLoading={resendStatus === 'loading'}
                            >
                                {resendStatus === 'idle' || resendStatus === 'loading' ? (
                                    <>
                                        <RotateCcw className="h-4 w-4 mr-2" />
                                        Resend
                                    </>
                                ) : resendStatus === 'success' ? (
                                    <>
                                        <Check className="h-4 w-4 mr-1" />
                                        Resent
                                    </>
                                ) : (
                                    <>
                                        <X className="h-4 w-4 mr-1" />
                                        Failed
                                    </>
                                )}
                            </Button>
                        </div>
                        <div>
                            <span className="text-sm">Response Body</span>
                            <pre className="mt-1 text-xs bg-gray-50 p-2 rounded overflow-auto font-mono whitespace-pre-wrap">
                                {execution.response_body ? (
                                    typeof execution.response_body === 'string' ? (
                                        execution.response_body
                                    ) : (
                                        JSON.stringify(execution.response_body, null, 2)
                                    )
                                ) : (
                                    <span className="text-sm text-gray-500">N/A</span>
                                )}
                            </pre>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
