import { TeamMember } from '@/schemas/entities/team-members.schema'
import { getTeamMembers } from '@/services/team-member.service'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const KEY = ['team-members']

export function useTeamMembers() {
    const { data: team_members = null, isLoading } = useQuery<TeamMember[]>({
        queryKey: KEY,
        queryFn: getTeamMembers,
    })
    const queryClient = useQueryClient()

    const invalidate = () => queryClient.invalidateQueries({ queryKey: KEY })

    return { team_members, invalidate, isLoading }
}
