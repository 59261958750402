import { useMe } from '@/components/hooks/use-me'
import { PageLayout } from '@/components/ui/layout/page-layout'
import { Button } from '@/shared/ui/base/button'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import CreditsUsage from '../settings/billing/components/credits-usage'
import CreditsUsageSkeleton from '../settings/billing/components/credits-usage-skeleton'
import RequestsTable from './api-request-table/api-request-table'

function UsagePage() {
    const { me } = useMe()
    useEffect(() => {
        document.title = 'Usage · Settings · TheirStack'
    }, [])

    return (
        <PageLayout
            title="Usage"
            description="Track and analyze your consumption across API calls, UI interactions, and webhook events, along with related billing information."
            breadcrumbs={[{ label: 'Home', href: '/' }, { label: 'Usage' }]}
            titleAction={
                <>
                    <Button variant="outline">
                        <Link to="/settings/billing">Billing</Link>
                    </Button>
                    <Button variant="outline">
                        <Link to="/settings/invoices">Invoices</Link>
                    </Button>
                </>
            }
        >
            {me ? <CreditsUsage me={me} className="mb-8" /> : <CreditsUsageSkeleton className="mb-8" />}
            <RequestsTable />
        </PageLayout>
    )
}

export default UsagePage
