import { Webhook as WebhookIcon } from 'lucide-react'

export function WebhookLogo({ url, size = 12 }: { url: string; size?: number }) {
    const square = (children: React.ReactNode) => (
        <div className={`w-${size} h-${size} rounded-sm border flex items-center justify-center shrink-0`}>
            {children}
        </div>
    )
    if (url.includes('api.clay.com')) {
        return square(<img src="/tools/clay-logo.png" alt="Clay Logo" className={`h-${size - 2} w-${size - 2}`} />)
    }
    if (url.includes('make.com')) {
        return square(<img src="/tools/make-logo.png" alt="Make Logo" className={`h-${size - 2} w-${size - 2}`} />)
    }
    if (url.includes('n8n.cloud')) {
        return square(<img src="/tools/n8n-logo.png" alt="N8N Logo" className={`h-${size - 2} w-${size - 2}`} />)
    }
    if (url.includes('zapier.com')) {
        return square(<img src="/tools/zapier-logo.png" alt="Zapier Logo" className={`h-${size - 2} w-${size - 2}`} />)
    }
    if (url.includes('airtable.com')) {
        return square(<img src="/tools/airtable-logo.png" alt="N8N Logo" className={`h-${size - 2} w-${size - 2}`} />)
    }
    return square(<WebhookIcon className={`h-${size - 2} w-${size - 2} p-1`} strokeWidth={1} />)
}
