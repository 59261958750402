import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { DownloadIcon } from '@radix-ui/react-icons'

export function InvoiceSkeletonCard() {
    return (
        <Card className="animate-pulse grid grid-cols-5 gap-4 p-4 items-center">
            <div>
                <p className="text-md font-medium bg-gray-300 rounded h-4 w-24"></p>
                <p className="text-xs text-muted-foreground bg-gray-300 rounded h-3 w-16 mt-1"></p>
            </div>
            <div>
                <p className="text-md font-medium bg-gray-300 rounded h-4 w-24"></p>
                <p className="text-xs text-muted-foreground bg-gray-300 rounded h-3 w-16 mt-1"></p>
            </div>
            <div>
                <p className="text-md font-medium bg-gray-300 rounded h-4 w-24"></p>
                <p className="text-xs text-muted-foreground bg-gray-300 rounded h-3 w-16 mt-1"></p>
            </div>
            <div>
                <div>
                    <p className="text-xs bg-gray-300 rounded h-3 w-32"></p>
                    <p className="text-xs bg-gray-300 rounded h-3 w-32 mt-1"></p>
                </div>
                <p className="text-xs text-muted-foreground bg-gray-300 rounded h-3 w-16 mt-1"></p>
            </div>
            <div className="justify-self-end">
                <Button variant="outline" size="icon" disabled>
                    <DownloadIcon className="w-4 h-4" />
                </Button>
            </div>
        </Card>
    )
}
