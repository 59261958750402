import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { raiseIfNotOk } from '@/lib/http-utils'
import { TeamMember, TeamMemberRole, teamMemberSchema } from '@/schemas/entities/team-members.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/teams'

export async function getTeamMembers(): Promise<TeamMember[]> {
    const res = await fetch(RESOURCE_URL + '/current/members', {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(teamMemberSchema).parse(await res.json())
}

export async function updateTeamMemberRole(userId: number, role: TeamMemberRole): Promise<boolean> {
    const res = await fetch(RESOURCE_URL + '/members/' + userId + '/role', {
        method: 'PUT',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ role }),
    })
    await raiseIfNotOk(res)
    return res.ok
}

export async function inviteTeamMember(email: string): Promise<boolean> {
    const res = await fetch(RESOURCE_URL + '/current/members', {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ email }),
    })
    await raiseIfNotOk(res)
    return res.ok
}

export async function removeTeamMember(userId: number): Promise<boolean> {
    const res = await fetch(RESOURCE_URL + '/current/members/' + userId, {
        method: 'DELETE',
        headers: getHTTPHeadersImpersonating(),
    })
    await raiseIfNotOk(res)
    return res.ok
}
