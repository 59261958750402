import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/base/select'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { autoRechargeRuleCreateSchema } from '@/schemas/entities/auto-recharge-rule.schema'
import { createAutoRechargeRule } from '@/services/billing.service'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/base/use-toast'

export const AutoRechargeAddButton = ({ buttonTitle }: { buttonTitle: string }) => {
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const form = useForm<z.infer<typeof autoRechargeRuleCreateSchema>>({
        resolver: zodResolver(autoRechargeRuleCreateSchema),
        defaultValues: {
            recharge_below: 5000,
            credit_type: 'api',
            recharge_amount: 20000,
        },
    })

    function onSubmit(data: z.infer<typeof autoRechargeRuleCreateSchema>) {
        setIsLoading(true)
        createAutoRechargeRule(data)
            .then(() => {
                setIsLoading(false)
                setOpen(false)
                queryClient.invalidateQueries({ queryKey: ['auto-recharge-rules'] })
                toast({
                    title: 'Rule created',
                })
            })
            .catch((error) => {
                setIsLoading(false)
                toast({
                    title: 'Failed to create rule',
                    description: error.message,
                    variant: 'destructive',
                })
            })
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">{buttonTitle}</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Configure Auto Recharge</DialogTitle>
                    <p className="text-sm text-muted-foreground mb-4">
                        Set when to automatically purchase more credits.
                    </p>
                </DialogHeader>
                <Form {...form}>
                    <form className="grid max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="recharge_below"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Recharge when below</FormLabel>
                                    <FormControl>
                                        <div className="flex items-center gap-2">
                                            <Button
                                                type="button"
                                                variant="outline"
                                                size="icon"
                                                onClick={() => field.onChange(Math.max(1000, field.value - 1000))}
                                            >
                                                -
                                            </Button>
                                            <Input
                                                type="number"
                                                {...field}
                                                onChange={(e) => field.onChange(parseInt(e.target.value))}
                                                className="w-32 text-center"
                                            />
                                            <Button
                                                type="button"
                                                variant="outline"
                                                size="icon"
                                                onClick={() => field.onChange(field.value + 1000)}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="credit_type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Type of credits</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select credit type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem value="api">API Credits</SelectItem>
                                            <SelectItem value="ui">Company credits</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="recharge_amount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Recharge amount</FormLabel>
                                    <Select
                                        onValueChange={(value) => field.onChange(parseInt(value))}
                                        defaultValue={field.value.toString()}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select recharge amount" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem value="3000">3,000 credits</SelectItem>
                                            <SelectItem value="5000">5,000 credits</SelectItem>
                                            <SelectItem value="10000">10,000 credits</SelectItem>
                                            <SelectItem value="20000">20,000 credits</SelectItem>
                                            <SelectItem value="50000">50,000 credits</SelectItem>
                                            <SelectItem value="100000">100,000 credits</SelectItem>
                                            <SelectItem value="200000">200,000 credits</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter className="pt-6">
                            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
                                {buttonTitle}
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
