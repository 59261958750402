import { useToast } from '@/components/ui/base/use-toast'
import { ConfirmActionDialog } from '@/components/ui/dialog/confirm-action-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { deleteInvoiceConsumption as removeConsumption } from '@/services/billing.service'
import { Button } from '@/shared/ui/base/button'
import { EraserIcon } from 'lucide-react'
import { useRef } from 'react'

export default function AdminActions({
    invoice_id,
    used_api_credits,
    used_ui_credits,
}: {
    invoice_id: string
    used_api_credits: number
    used_ui_credits: number
}) {
    const confirmDeleteDialogRef = useRef<OpenDialogHandle>(null)
    const { toast } = useToast()
    return (
        <>
            <Button variant="destructive" size="icon" onClick={() => confirmDeleteDialogRef.current?.open()}>
                <EraserIcon className="w-4 h-4" />
            </Button>
            <a href={`https://dashboard.stripe.com/invoices/${invoice_id}`} target="_blank" rel="noopener noreferrer">
                <Button variant="outline" size="icon">
                    <img
                        src="https://b.stripecdn.com/manage-statics-srv/assets/public/favicon.ico"
                        alt="Stripe"
                        className="w-5 h-5"
                    />
                </Button>
            </a>
            <ConfirmActionDialog
                ref={confirmDeleteDialogRef}
                title={`Are you sure you want to remove consumption for invoice ${invoice_id}?`}
                description={`This action cannot be undone and will remove ${used_api_credits} API credits and ${used_ui_credits} company credits from this invoice.`}
                confirmAction={() => {
                    removeConsumption(invoice_id)
                        .then(() => {
                            toast({ title: 'Consumption has been removed' })
                            confirmDeleteDialogRef.current?.close()
                            window.location.reload()
                        })
                        .catch(() => {
                            toast({ title: 'Failed to remove consumption', variant: 'destructive' })
                            confirmDeleteDialogRef.current?.close()
                        })
                }}
                cancelAction={() => confirmDeleteDialogRef.current?.close()}
                actionText="Remove consumption"
                actionVariant="destructive"
            />
        </>
    )
}
