export const SIGNUP_PATH = '/signup'
export const HOME_PATH = '/home'
export const SEARCH_PATH = '/search'

export const JOB_SEARCH_PATH = SEARCH_PATH + '/jobs'
export const JOB_SEARCH_NEW_PATH = JOB_SEARCH_PATH + '/new'
export const JOB_SEARCH_ID_PATH = JOB_SEARCH_PATH + '/:searchId'

export const TECH_SEARCH_PATH = SEARCH_PATH + '/tech'
export const TECH_SEARCH_NEW_PATH = TECH_SEARCH_PATH + '/new'
export const TECH_SEARCH_ID_PATH = TECH_SEARCH_PATH + '/:searchId'

export const COMPANY_SEARCH_PATH = SEARCH_PATH + '/companies'
export const COMPANY_SEARCH_NEW_PATH = COMPANY_SEARCH_PATH + '/new'
export const COMPANY_SEARCH_ID_PATH = COMPANY_SEARCH_PATH + '/:searchId'

export const COMPANY_LIST_PATH = '/company-list'
export const COMPANY_LIST_ID_PATH = '/company-list/:listId'

export const TECHNOLOGY_PATH = '/technology'
export const TECHNOLOGY_NEW_PATH = TECHNOLOGY_PATH + '/new'

export const DATASETS_PATH = '/dataset'
export const WEBHOOKS_PATH = '/webhooks'
export const WEBHOOKS_NEW_PATH = '/webhooks/new'
export const WEBHOOKS_EDIT_PATH = '/webhooks/:id/edit'
export const WEBHOOK_PATH = '/webhooks/:id'

export const USAGE_PATH = '/usage'
