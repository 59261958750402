import { WebhookExecutionsChart } from '@/components/ui/webhooks/webhook-details/webhook-executions-chart'
import { useFetchDataForTable } from '@/pages/company-list/[id]/components/use-fetch-data-for-table'
import { CreditsConsumption } from '@/schemas/entities/credits-consumption.schema'
import { Request } from '@/schemas/entities/recent-search.schema'
import { RequestCount } from '@/schemas/entities/request-count.schema'
import { RequestSearchParams } from '@/schemas/request/request-search-params.schema'
import { getCreditsConsumption } from '@/services/billing.service'
import { exportRequests, getRequestCount, getRequests } from '@/services/requests.service'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { CreditsUsageChart } from './components/credits-consumption.chart'
import { DatePickerWithRange, OPTIONS_DATE_RANGE } from './components/data-range-picker'
import { DataTableWithPagination } from './components/data-table'
import { columns, hiddenColumns } from './components/data-table-columns'
import { APIRequestCountChart } from './components/request-count.chart'

export default function RequestsTable() {
    const [searchParams, setSearchParams] = useState<RequestSearchParams>({
        start_datetime: OPTIONS_DATE_RANGE[1].value.from,
        end_datetime: OPTIONS_DATE_RANGE[1].value.to,
    })

    const { table, isDataFetching, onChangeFilters } = useFetchDataForTable<RequestSearchParams, Request>(
        searchParams,
        getRequests,
        exportRequests,
        columns,
        10000,
        undefined,
        hiddenColumns
    )

    const { data: creditsConsumption } = useQuery<CreditsConsumption[]>({
        queryKey: ['credits-consumption', searchParams.start_datetime, searchParams.end_datetime],
        queryFn: () => getCreditsConsumption(searchParams.start_datetime, searchParams.end_datetime),
    })

    const { data: requestCount } = useQuery<RequestCount[]>({
        queryKey: ['request-count', searchParams.start_datetime, searchParams.end_datetime],
        queryFn: () => getRequestCount(searchParams.start_datetime, searchParams.end_datetime),
    })

    const onChangeDate = (date: DateRange | undefined) => {
        if (date?.from && date?.to) {
            setSearchParams({ start_datetime: date.from, end_datetime: date.to })
            onChangeFilters({ start_datetime: date.from, end_datetime: date.to })
        }
    }

    return (
        <>
            <div className="space-y-6">
                <div className="flex justify-between">
                    <DatePickerWithRange onChangeDate={onChangeDate} />
                </div>
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-medium">Credits Usage</h3>
                        <p className="text-sm text-muted-foreground">Showing total credits consumed</p>
                    </div>

                    <CreditsUsageChart data={creditsConsumption} />
                </div>
            </div>

            <div className="space-y-6 mt-8">
                <div>
                    <h3 className="text-lg font-medium">Webhook executions</h3>
                    <p className="text-sm text-muted-foreground">View all webhook executions for your account.</p>
                </div>

                <div className="space-y-3">
                    <WebhookExecutionsChart start_datetime={searchParams.start_datetime} />
                </div>
            </div>

            <div className="space-y-6 mt-8">
                <div>
                    <h3 className="text-lg font-medium">Requests</h3>
                    <p className="text-sm text-muted-foreground">View all api and ui requests for your account.</p>
                </div>

                <div className="space-y-3">
                    <APIRequestCountChart data={requestCount} />
                </div>

                <DataTableWithPagination
                    table={table}
                    columns={columns}
                    isDataFetching={isDataFetching}
                    totalResults={0}
                />
            </div>
        </>
    )
}
