import { getHTTPHeaders } from '@/lib/authentication/authentication-http-headers'
import { constructURL, raiseIfNotOk } from '@/lib/http-utils'
import { CreateKeyword } from '@/schemas/entities/new-technology'
import { ExtractedTechnologyDetails } from '@/schemas/entities/technology-extracted-details'
import { Technology } from '@/schemas/entities/technology.schema'

const RESOURCE_URL = `${import.meta.env.VITE_THEIRSTACK_API_SERVER_URL}/v0/catalog/technologies`

export async function getTechnologies(page: number, limit: number): Promise<Technology[]> {
    const url = constructURL(RESOURCE_URL, {
        limit,
        page,
        source: 'app',
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=86400' } })

    await raiseIfNotOk(res)
    return (await res.json()) as Technology[]
}

export async function getTechnologiesByName(name_pattern: string): Promise<Technology[]> {
    const url = constructURL(RESOURCE_URL, {
        limit: 30,
        name_pattern,
        source: 'app',
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=600' } })

    await raiseIfNotOk(res)
    return (await res.json()) as Technology[]
}

export async function getTechnologiesBySlugs(slugs: string[]): Promise<Technology[]> {
    if (!slugs || slugs.length === 0) {
        return []
    }
    const url = constructURL(RESOURCE_URL, {
        slugs: slugs,
        source: 'app',
    })
    const res = await fetch(url, { method: 'GET', headers: { ...getHTTPHeaders(), 'Cache-Control': 'max-age=86400' } })

    await raiseIfNotOk(res)
    return (await res.json()) as Technology[]
}

export async function extractTechnologyFromUrl(url: string): Promise<ExtractedTechnologyDetails> {
    const apiUrl = constructURL(`${RESOURCE_URL}/extract_technology_from_url`, { url })
    const res = await fetch(apiUrl, { method: 'GET', headers: getHTTPHeaders() })
    await raiseIfNotOk(res)
    return (await res.json()) as ExtractedTechnologyDetails
}

export async function createTechnology(technology: CreateKeyword): Promise<boolean> {
    const res = await fetch(RESOURCE_URL, {
        method: 'POST',
        headers: getHTTPHeaders(),
        body: JSON.stringify(technology),
    })
    await raiseIfNotOk(res)
    return true
}
