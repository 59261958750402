import { z } from 'zod'

export enum InvoiceStatus {
    PAID = 'paid',
    DRAFT = 'draft',
    OPEN = 'open',
    REFUNDED = 'refunded',
    UNCOLLECTIBLE = 'uncollectible',
    VOID = 'void',
}

export const invoiceSchema = z.object({
    id: z.string().describe('ID of the invoice'),
    number: z.string().describe('Number of the invoice'),
    total: z.number().describe('Total amount of the invoice'),
    currency: z.string().describe('Currency of the invoice'),
    hosted_invoice_url: z.string().describe('URL of the invoice'),
    status: z.nativeEnum(InvoiceStatus).describe('Status of the invoice stripe.Invoice.status'),
    ui_credits: z.number().default(0).describe('Number of UI credits available'),
    used_ui_credits: z.number().default(0).describe('Number of UI credits used'),
    api_credits: z.number().default(0).describe('Number of API credits available'),
    used_api_credits: z.number().default(0).describe('Number of API credits used'),
    created_at: z.string().describe('Creation timestamp of the invoice'),
    has_subscription: z.boolean().describe('Whether the invoice has a subscription associated with it'),
})

export type Invoice = z.infer<typeof invoiceSchema>
