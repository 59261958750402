import { Input } from '@/components/ui/base/input'
import ExportButton from '@/components/ui/table/actions/action-export-data/export-button'
import FindPeopleButton from '@/components/ui/table/actions/action-find-people'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { CompanyFromListSearchParams } from '@/schemas/request/companies-from-list-search-params.schema'
import { companySchema } from '@/shared/schemas/entity/company.schema'
import { Table } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useCustomDeferredValue } from '@/shared/hooks/use-deferred-value'

interface DataTableToolbarProps {
    table: Table<CompanyFromList>
    filter_by?: string
    isDataFetching: boolean
    onChangeFilters: (_params: CompanyFromListSearchParams) => void
    searchedSP: CompanyFromListSearchParams
}

export function DataTableToolbar({ table, isDataFetching, onChangeFilters, searchedSP }: DataTableToolbarProps) {
    const [searchQuery, setSearchQuery] = useState('')
    const deferredSearchQuery = useCustomDeferredValue(searchQuery, { delay: 400 })

    useEffect(() => {
        const searchParams = { ...searchedSP, company_name_partial: deferredSearchQuery }
        onChangeFilters(searchParams)
    }, [deferredSearchQuery])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value)
    }

    return (
        <>
            <div className="flex flex-wrap justify-between items-end gap-2 content-end">
                <Input
                    placeholder="Filter by company name..."
                    onChange={handleSearchChange}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                <div className="flex flex-grap gap-1 border rounded-md bg-white">
                    <ExportButton<CompanyFromList>
                        recordName="companies"
                        table={table}
                        isDataFetching={isDataFetching}
                        isRowBlurred={() => false}
                        getUniqueCompanyId={(company) => company.company_object?.id ?? ''}
                    />
                    <FindPeopleButton
                        isDataFetching={isDataFetching}
                        table={table}
                        fromRowToCompany={(rows) => rows.map((row) => companySchema.parse(row.company_object))}
                    />
                </div>
            </div>
        </>
    )
}
