import { useToast } from '@/components/ui/base/use-toast'
import { get_search_url } from '@/pages/home/components/saved-searches/recent-searches-card'
import { SearchType } from '@/schemas/entities/recent-search.schema'
import { Button } from '@/shared/ui/base/button'
import { Share2 } from 'lucide-react'

interface ShareSearchButtonProps {
    searchType: SearchType
    searchParams: any
    className?: string
    size?: 'default' | 'sm' | 'lg' | 'icon' | 'icon_xs'
}

export function ShareSearchButton({
    searchType,
    searchParams,
    className = '',
    size = 'icon_xs',
}: ShareSearchButtonProps) {
    const { toast } = useToast()

    const handleShareSearch = () => {
        if (!searchParams) return

        navigator.clipboard.writeText(
            import.meta.env.VITE_REACT_APP_API_SERVER_URL + get_search_url(searchType, searchParams)
        )
        toast({
            title: 'Copied to clipboard',
            description: 'The search URL has been copied to your clipboard.',
        })
    }

    return (
        <Button variant="ghost" size={size} className={`text-sm font-normal ${className}`} onClick={handleShareSearch}>
            <Share2 className="h-4 w-4" />
        </Button>
    )
}
