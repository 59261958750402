import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import TallyForm from '@/components/ui/tally.form'
import { UserWithTeam } from '@/schemas/entities/user'
import { cancelSubscription } from '@/services/billing.service'
import { Dialog, DialogContent, DialogTitle } from '@/shared/ui/base/dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import OneTimePurchaseCard from './one-time-purchase-card'
import { PlanCard } from './plan-card'

interface BillingDetailsProps {
    me: UserWithTeam
}

export default function BillingDetails({ me }: BillingDetailsProps) {
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState<string | null>(null)
    const [isCancellationLoading, setIsCancellationLoading] = useState(false)
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })

    const subscriptions = useMemo(() => {
        return (
            me?.team?.plans.filter(
                (plan) =>
                    plan.recurring &&
                    ['active', 'incomplete', 'canceled'].includes(plan.status) &&
                    plan.invoices.some((p) => p.used_api_credits < p.api_credits || p.used_ui_credits < p.ui_credits)
            ) || []
        )
    }, [me])

    const oneTimePurchases = useMemo(() => {
        return (
            me?.team?.plans.filter(
                (plan) =>
                    !plan.recurring &&
                    ['active', 'incomplete'].includes(plan.status) &&
                    plan.invoices.some((p) => p.used_api_credits < p.api_credits || p.used_ui_credits < p.ui_credits)
            ) || []
        )
    }, [me])

    const onConfirmCancel = (subscription_id: string) => {
        setIsCancellationLoading(true)
        cancelSubscription(subscription_id)
            .then(() => {
                setSubscriptionIdToCancel(null)
                queryClient
                    .invalidateQueries({ queryKey: ['me'] })
                    .then(() => {
                        setIsCancellationLoading(false)
                    })
                    .catch(() => {
                        setIsCancellationLoading(false)
                        showErrorNotification()
                    })
            })
            .catch(() => {
                setSubscriptionIdToCancel(null)
                setIsCancellationLoading(false)
                showErrorNotification()
            })
    }

    const showConfirmCancelDialog = (subscription_id: string) => {
        setShowCancelDialog(true)
        setSubscriptionIdToCancel(subscription_id)
    }

    return (
        <>
            <div>
                <h2 className="text-lg font-medium mb-2">Plans</h2>
                <p className="text-sm text-muted-foreground mb-4">
                    Below are your active plans. You can track all your plans and remaining credits in the{' '}
                    <Link to="/settings/invoices" className="underline">
                        invoices page
                    </Link>
                    .
                </p>
                <div className="flex flex-col gap-2 mb-4">
                    {subscriptions
                        .sort((a, b) => a.credits - b.credits)
                        .map((plan) => (
                            <PlanCard
                                key={plan.name + plan.credits + plan.created_at}
                                plan={plan}
                                isCancellationLoading={isCancellationLoading}
                                cancelSubscription={showConfirmCancelDialog}
                            />
                        ))}
                </div>
            </div>

            {oneTimePurchases.length > 0 && (
                <div>
                    <h2 className="text-lg font-medium mb-2">One-time purchases</h2>
                    <p className="text-sm text-muted-foreground mb-4">
                        Below are your one-time purchases with remaining credits. You can view all your plans and credit
                        balances in the{' '}
                        <Link to="/settings/invoices" className="underline">
                            invoices page
                        </Link>
                        .
                    </p>
                    <div className="flex flex-col gap-2">
                        {oneTimePurchases
                            ?.sort((a, b) => a.credits - b.credits)
                            .map((plan) => (
                                <OneTimePurchaseCard key={plan.name + plan.credits + plan.created_at} plan={plan} />
                            ))}
                    </div>
                </div>
            )}

            {showCancelDialog && (
                <Dialog open={true} onOpenChange={setShowCancelDialog}>
                    <DialogContent
                        className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]"
                        aria-describedby={undefined}
                    >
                        <VisuallyHidden.Root asChild>
                            <DialogTitle>Cancel subscription</DialogTitle>
                        </VisuallyHidden.Root>
                        <TallyForm
                            src={`https://tally.so/embed/w42Jqo?hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(me.email)}`}
                            onFormSubmitted={() => {
                                if (subscriptionIdToCancel) {
                                    onConfirmCancel(subscriptionIdToCancel)
                                }
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}
