import { isObjectEmpty } from '@/lib/object-utils'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { CompaniesSearchParams } from '@/shared/schemas/request/companies-search-params.schema'
import { ColumnDef, Table, VisibilityState } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import {
    getNewDynamicColumns,
    EXPANDED_TECHNOLOGY_COLUMN_PREFIX,
    FILTERED_TECHNOLOGY_COLUMN_PREFIX,
} from './columns-company'
import { areStringArraysEqual } from '@/shared/utils/array-utils'

const TECHNOLOGY_COLUMNS_POSITION = 8

export function useColumnsVisibilityBasedOnSearch(
    table: Table<FullCompany>,
    searchedSearchParams: CompaniesSearchParams,
    columnVisibility: VisibilityState,
    columns: ColumnDef<FullCompany>[],
    setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>,
    setColumns: React.Dispatch<React.SetStateAction<ColumnDef<FullCompany>[]>>
) {
    const [expandedTechnologies, setExpandedTechnologies] = useState<string[]>([])
    const [filteredTechnologies, setFilteredTechnologies] = useState<string[]>([])

    useEffect(() => {
        showOrHideColumnsBasedOnSearchParams()
        updateExpandedTechnologies()
    }, [searchedSearchParams])

    useEffect(() => {
        if (!table.getRowModel || !table.getRowModel().rows) return
        updateFilteredTechnologies()
    }, [table.getRowModel?.().rows])

    useEffect(() => {
        updateColumnsWithAllTechnologies()
    }, [expandedTechnologies, filteredTechnologies])

    const showOrHideColumnsBasedOnSearchParams = () => {
        if (isObjectEmpty(searchedSearchParams.job_filters)) {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = false
            setColumnVisibility(newColumnVisibility)
        } else {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = true
            setColumnVisibility(newColumnVisibility)
        }
    }

    const updateExpandedTechnologies = () => {
        const new_expanded_technologies = searchedSearchParams.expand_technology_slugs || []
        if (!areStringArraysEqual(new_expanded_technologies, expandedTechnologies)) {
            setExpandedTechnologies(new_expanded_technologies)
        }
    }

    const updateFilteredTechnologies = () => {
        if (!table.getRowModel || table.getRowModel().rows.length === 0) {
            const new_filtered_technologies: string[] = []
            if (!areStringArraysEqual(new_filtered_technologies, filteredTechnologies)) {
                setFilteredTechnologies(new_filtered_technologies)
            }
            return
        }

        const rows = table.getRowModel().rows
        const new_filtered_technologies = Array.from(
            new Set(
                rows
                    .map((row) => row.original.technologies_found)
                    .flat()
                    .map((tech) => tech.technology.slug)
            )
        ).filter((tech) => !searchedSearchParams.expand_technology_slugs?.includes(tech))

        if (!areStringArraysEqual(new_filtered_technologies, filteredTechnologies)) {
            setFilteredTechnologies(new_filtered_technologies)
        }
    }

    const updateColumnsWithAllTechnologies = () => {
        const baseColumns = columns.filter(
            (column) =>
                !column.id?.includes(EXPANDED_TECHNOLOGY_COLUMN_PREFIX) &&
                !column.id?.includes(FILTERED_TECHNOLOGY_COLUMN_PREFIX)
        )

        setColumns([
            ...baseColumns.slice(0, TECHNOLOGY_COLUMNS_POSITION),
            ...getNewDynamicColumns(expandedTechnologies, EXPANDED_TECHNOLOGY_COLUMN_PREFIX),
            ...getNewDynamicColumns(filteredTechnologies, FILTERED_TECHNOLOGY_COLUMN_PREFIX),
            ...baseColumns.slice(TECHNOLOGY_COLUMNS_POSITION),
        ])
    }

    return {}
}
