import { useWebhooks } from '@/components/hooks/use-webhooks'
import { Card } from '@/shared/ui/base/card'
import { Search, Target, Webhook } from 'lucide-react'
import { EmptyState } from '../empty-state'
import { addMiddleEllipsis } from './webhook-details/webhook-details'
import { WebhookLogo } from './webhook-details/webhook-logo'
import { WebhookStatus } from './webhook-details/webhook-status'

export function WebhookList({ handleView, searchId }: { handleView: (webhookId: number) => void; searchId?: number }) {
    const { webhooks, isLoading } = useWebhooks()

    if (isLoading)
        return (
            <div className="grid gap-4 grid-cols-1">
                {[...Array(3)].map((_, i) => (
                    <div className="h-16 bg-gray-200 rounded animate-pulse" key={i} />
                ))}
            </div>
        )

    if (webhooks.filter((webhook) => (searchId ? webhook.search_id === searchId : true)).length === 0) {
        return (
            <EmptyState
                className="pt-12"
                icon={Webhook}
                title="No webhooks found"
                description={
                    searchId
                        ? "You don't have any webhooks for this search yet. Create one to start receiving notifications."
                        : "You don't have any webhooks yet. Create one to start receiving notifications."
                }
            />
        )
    }

    return (
        <div className="grid gap-4 grid-cols-1">
            {webhooks
                .filter((webhook) => (searchId ? webhook.search_id === searchId : true))
                .sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1))
                .map((webhook) => (
                    <Card
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        key={webhook.id}
                        onClick={() => handleView(webhook.id)}
                    >
                        <div className="flex justify-between items-center gap-2">
                            <div className="flex items-center gap-2 w-fit">
                                <WebhookLogo url={webhook.url} />
                                <div className="flex flex-col items-start gap-0.5 overflow-hidden ">
                                    <h2 className="truncate w-fit text-ellipsis hover:underline">
                                        {addMiddleEllipsis(webhook.url)}
                                    </h2>
                                    <p className="text-xs text-gray-500 inline-flex items-center">
                                        {' '}
                                        <Target className="h-3 w-3 mr-1" strokeWidth={2} />
                                        {webhook.description ? webhook.description : 'No description'}
                                    </p>
                                    <p className="text-xs text-gray-500 inline-flex items-center whitespace-nowrap text-ellipsis">
                                        {' '}
                                        <Search className="h-3 w-3 mr-1" strokeWidth={2} />
                                        {webhook.search_name}
                                    </p>
                                </div>
                            </div>
                            <WebhookStatus is_active={webhook.is_active} />
                        </div>
                    </Card>
                ))}
        </div>
    )
}
