import { PageLayout } from '@/components/ui/layout/page-layout'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { Button } from '@/shared/ui/base/button'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import BillingDetails from './components/billing-details'
import BillingDetailsSkeleton from './components/billing-details-skeleton'
import CreditsUsage from './components/credits-usage'
import CreditsUsageSkeleton from './components/credits-usage-skeleton'
import { ManageBilling } from './components/manage-billing'
import { AutoRecharge } from './components/auto-recharge/auto-recharge'
import { CompaniesVisibility } from './components/companies-visibility'

export const REVEAL_COMPANIES_AUTOMATICALLY = 'ts-reveal-companies-automatically'

export function isRevealCompaniesAutomaticallyEnabled(): boolean {
    if (localStorage.getItem(REVEAL_COMPANIES_AUTOMATICALLY)) {
        return localStorage.getItem(REVEAL_COMPANIES_AUTOMATICALLY) === 'true'
    } else {
        return false
    }
}

export function setIsRevealCompaniesAutomaticallyEnabled(enabled: boolean) {
    localStorage.setItem(REVEAL_COMPANIES_AUTOMATICALLY, enabled ? 'true' : 'false')
}

export default function BillingPage() {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    useEffect(() => {
        document.title = 'Billing · Settings · TheirStack'
    }, [])

    return (
        <PageLayout
            title="Billing"
            description="Manage your plans, credits, invoices and payment method."
            className="mb-16"
            breadcrumbs={[{ href: '/', label: 'Home' }, { href: '/settings', label: 'Settings' }, { label: 'Billing' }]}
            titleAction={
                <>
                    <Link to="/settings/invoices">
                        <Button variant="outline">Invoices</Button>
                    </Link>
                    <Link to="/usage">
                        <Button variant="outline">Usage</Button>
                    </Link>
                </>
            }
        >
            <div className="space-y-12">
                {!me ? (
                    <>
                        <CreditsUsageSkeleton />
                        <BillingDetailsSkeleton />
                    </>
                ) : (
                    <>
                        <CreditsUsage me={me} />
                        <BillingDetails me={me} />
                    </>
                )}
                <ManageBilling />
                <AutoRecharge />
                <CompaniesVisibility />
            </div>
        </PageLayout>
    )
}
