import { cn } from '@/lib/utils'
import { Separator } from '@/shared/ui/base/separator'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../base/breadcrumb'
import { SidebarTrigger, useSidebar } from '../base/sidebar'

type BreadcrumbLink = {
    href?: string
    label: string
}

const BREADCRUMB_HEIGHT = 30

export function PageLayout({
    children,
    title,
    description,
    className,
    breadcrumbs = [],
    fullWidth = false,
    breadcrumbAction,
    titleAction,
    overflowHidden = false,
}: {
    children: React.ReactNode
    title?: string
    description?: string
    className?: string
    breadcrumbs?: BreadcrumbLink[]
    fullWidth?: boolean
    overflowHidden?: boolean
    breadcrumbAction?: React.ReactNode
    titleAction?: React.ReactNode
}) {
    const { isMobile } = useSidebar()
    const breadcrumbsLength = breadcrumbs.reduce((total, crumb) => total + crumb.label.length, 0)
    const showFullBreadcrumbs = breadcrumbsLength < BREADCRUMB_HEIGHT || !isMobile
    return (
        <div
            className={cn({ 'overflow-hidden': overflowHidden })}
            style={{ height: overflowHidden ? 'calc(100vh - 8px)' : 'auto' }}
        >
            <header className="flex h-12 shrink-0 items-center gap-2 bg-sidebar md:bg-transparent">
                <div className="flex items-center gap-2 px-4">
                    <SidebarTrigger className="-ml-1" />
                    {breadcrumbs.length > 0 && (
                        <>
                            {showFullBreadcrumbs ? (
                                <>
                                    <Separator orientation="vertical" className="md:mr-2 h-4" />
                                    <Breadcrumb>
                                        <BreadcrumbList>
                                            {breadcrumbs.map((crumb, index) => (
                                                <BreadcrumbItem key={index}>
                                                    {index < breadcrumbs.length - 1 ? (
                                                        <BreadcrumbLink to={crumb.href ?? ''}>
                                                            {crumb.label}
                                                        </BreadcrumbLink>
                                                    ) : (
                                                        <BreadcrumbPage>{crumb.label}</BreadcrumbPage>
                                                    )}
                                                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                                                </BreadcrumbItem>
                                            ))}
                                        </BreadcrumbList>
                                    </Breadcrumb>
                                </>
                            ) : (
                                <BreadcrumbPage>{breadcrumbs[breadcrumbs.length - 1]?.label}</BreadcrumbPage>
                            )}
                            {breadcrumbAction != null && breadcrumbAction}
                        </>
                    )}
                </div>
            </header>
            <div
                className={cn(
                    'grow p-3 bg-white w-full px-4 xl:px-6',
                    fullWidth ? 'max-w-full' : 'max-w-screen-xl',
                    'mx-auto',
                    overflowHidden ? 'flex flex-col overflow-hidden' : '',
                    className
                )}
                style={{ height: overflowHidden ? 'calc(100vh - 48px)' : 'auto' }}
            >
                {title && (
                    <div className="hidden md:flex flex-col pb-6 space-y-2">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-col items-start gap-2">
                                <h1 className="text-2xl font-semibold tracking-tight text-black">{title}</h1>
                                {description && <p className="text-sm text-neutral-600">{description}</p>}
                            </div>
                            {titleAction && <div className="flex flex-row items-center gap-2">{titleAction}</div>}
                        </div>
                        <Separator />
                    </div>
                )}
                {children}
            </div>
        </div>
    )
}
