import { Card } from '@/shared/ui/base/card'

export default function CreditsUsageSkeleton({ className }: { className?: string }) {
    return (
        <div className={className}>
            <div className="h-7 w-32 mb-4 bg-gray-300 rounded"></div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                {/* Company Credits Card */}
                <Card className="p-4 animate-pulse">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="bg-green-100 rounded-md w-10 h-10 mb-2"></div>
                        <div className="flex flex-col">
                            <div className="h-5 w-48 mb-2 bg-gray-300 rounded"></div>
                            <div className="h-4 w-full mb-4 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="grow flex flex-col gap-1 items-start">
                            <div className="h-4 w-full mb-2 bg-gray-300 rounded"></div>
                            <div className="h-2 w-full bg-gray-300 rounded"></div>
                        </div>
                        <div className="h-8 w-24 bg-gray-300 rounded"></div>
                    </div>
                </Card>

                {/* API Credits Card */}
                <Card className="p-4 animate-pulse">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="bg-blue-100 rounded-md w-10 h-10 mb-2"></div>
                        <div className="flex flex-col">
                            <div className="h-5 w-48 mb-2 bg-gray-300 rounded"></div>
                            <div className="h-4 w-full mb-4 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="grow flex flex-col gap-1 items-start">
                            <div className="h-4 w-full mb-2 bg-gray-300 rounded"></div>
                            <div className="h-2 w-full bg-gray-300 rounded"></div>
                        </div>
                        <div className="h-8 w-24 bg-gray-300 rounded"></div>
                    </div>
                </Card>
            </div>
        </div>
    )
}
