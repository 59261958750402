import { Progress } from '@/components/ui/base/progress'
import UpgradeButton from '@/components/ui/layout/components/upgrade_button'
import { cn } from '@/lib/utils'
import { hasPaidSubscription } from '@/schemas/entities/plan'
import { UserWithTeam } from '@/schemas/entities/user'
import { Card } from '@/shared/ui/base/card'
import CreditsIcon from '@/shared/ui/credits/credits-icon'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { Link } from 'react-router-dom'

interface CreditUsageCardProps {
    title: string
    description: string
    iconColor: 'green' | 'blue'
    creditsUsed: number
    totalCredits: number
    linkText?: string
    linkTo?: string
    me: UserWithTeam
    upgradeButtonDefaultTab?: 'app' | 'api' | 'dataset' | undefined
}

export default function CreditUsageCard({
    title,
    description,
    iconColor,
    creditsUsed,
    totalCredits,
    linkText,
    linkTo,
    me,
    upgradeButtonDefaultTab,
}: CreditUsageCardProps) {
    const usagePercentage = (creditsUsed / totalCredits) * 100
    const isLowOnCredits = usagePercentage > 80

    return (
        <Card className="p-4">
            <div className="flex flex-row gap-2 items-center">
                <div
                    className={cn(
                        'rounded-md w-10 h-10 flex justify-center items-center mb-2',
                        iconColor === 'green' ? 'bg-green-200 text-green-800' : 'bg-blue-200 text-blue-800'
                    )}
                >
                    <CreditsIcon className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                    <h3 className="text-base font-medium ">{title}</h3>
                    <p className="text-xs text-gray-500 mb-4">{description}</p>
                </div>
            </div>

            <div className="flex flex-row gap-2 items-center">
                <div className="grow flex flex-col gap-1 items-start">
                    <p className="text-xs">
                        {formatNumberWithComma(creditsUsed)} used of {formatNumberWithComma(totalCredits)}{' '}
                        {linkText && linkTo && (
                            <Link to={linkTo} className="pl-2 underline">
                                {linkText}
                            </Link>
                        )}
                    </p>
                    <Progress
                        className="h-2"
                        value={usagePercentage}
                        indicatorClassName={cn(isLowOnCredits ? 'bg-red-400' : '')}
                    />
                </div>
                {!hasPaidSubscription(me) && (
                    <UpgradeButton variant="default" showIcon={false} size="xs" defaultTab={upgradeButtonDefaultTab} />
                )}
                {hasPaidSubscription(me) && (
                    <UpgradeButton
                        label="Buy more"
                        variant="default"
                        mode="one_time"
                        showIcon={false}
                        size="xs"
                        defaultTab={upgradeButtonDefaultTab}
                    />
                )}
            </div>
        </Card>
    )
}
