import { PaymentPendingBanner } from '@/components/ui/banners/payment-pending-banner'
import { Alert, AlertDescription, AlertTitle } from '@/shared/ui/base/alert'
import { SidebarInset, SidebarProvider } from '@/components/ui/base/sidebar'
import { Toaster } from '@/components/ui/base/toaster'
import OnboardingFormDialog from '@/components/ui/dialog/onboarding-form-dialog'
import { AIChatProvider, useAIChat } from '@/components/ui/sidebar/ai-chat-context'
import { AIChatSidebar } from '@/components/ui/sidebar/ai-chat-sidebar'
import { AppSidebar } from '@/components/ui/sidebar/app-sidebar'
import { JobsTableProvider } from '@/components/ui/sidebar/jobs-table-context'
import { cn } from '@/lib/utils'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Outlet } from 'react-router-dom'

function MainContent() {
    const { isOpen, sidebarWidth } = useAIChat()
    const showServiceUnavailableBanner = false

    return (
        <SidebarInset
            className={cn(
                'md:peer-data-[variant=inset]:m-0 md:peer-data-[variant=inset]:mt-2 md:peer-data-[variant=inset]:rounded-none md:peer-data-[variant=inset]:rounded-tl-xl',
                'transition-all duration-300 ease-in-out',
                isOpen ? ' md:peer-data-[variant=inset]:rounded-tr-xl' : ''
            )}
            style={{
                marginRight: isOpen ? sidebarWidth : '0px',
            }}
        >
            <div className="grid grid-cols-1 max-w-full">
                <PaymentPendingBanner className="flex-none" />
                {showServiceUnavailableBanner && <UnavailableBanner className="flex-none" />}
                <Outlet />
                <Toaster />
                <OnboardingFormDialog />
            </div>
        </SidebarInset>
    )
}

function MainLayout() {
    return (
        <SidebarProvider>
            <JobsTableProvider>
                <AIChatProvider>
                    <AppSidebar />
                    <MainContent />
                    <AIChatSidebar />
                </AIChatProvider>
            </JobsTableProvider>
        </SidebarProvider>
    )
}

export default MainLayout

function UnavailableBanner({ className }: { className?: string }) {
    return (
        <div className={cn('p-2 bg-white-100', className)}>
            <Alert className="bg-white" variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Service temporarily unavailable</AlertTitle>
                <AlertDescription>
                    TheirStack is currently unavailable due to scheduled maintenance until 17/06/2024 16:30 CET. We
                    apologize for the inconvenience.
                </AlertDescription>
            </Alert>
        </div>
    )
}
