import { getWebhookExecutionCountPerDay } from '@/services/webhook.service'
import { useQuery } from '@tanstack/react-query'

export function useWebhookExecutionCountPerDay(
    start_datetime: Date,
    end_datetime: Date,
    webhook_id?: number,
    enabled: boolean = true
) {
    const { data: webhookExecutionCount = [], isLoading } = useQuery({
        queryKey: ['webhook-execution-count-per-day', webhook_id, start_datetime],
        queryFn: () => getWebhookExecutionCountPerDay(start_datetime, end_datetime, webhook_id),
        enabled: enabled,
    })
    return { webhookExecutionCount, isLoading }
}
