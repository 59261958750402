import { z } from 'zod'

export const creditTypeSchema = z.enum(['api', 'ui'])
export type CreditType = z.infer<typeof creditTypeSchema>

export const autoRechargeRuleSchema = z.object({
    id: z.number(),
    team_id: z.number(),
    credit_type: creditTypeSchema,
    recharge_below: z.number().positive(),
    recharge_amount: z.number().positive(),
    created_at: z.string(),
    updated_at: z.string(),
})

export type AutoRechargeRule = z.infer<typeof autoRechargeRuleSchema>

export const autoRechargeRuleCreateSchema = z.object({
    credit_type: creditTypeSchema,
    recharge_below: z.number().min(1000),
    recharge_amount: z.number().min(1000),
})

export type AutoRechargeRuleCreate = z.infer<typeof autoRechargeRuleCreateSchema>
